const fbsTeams = [
  {
    id: 2005,
    school: 'Air Force',
    mascot: 'Falcons',
    abbreviation: 'AFA',
    alt_name1: null,
    alt_name2: 'AFA',
    alt_name3: 'Air Force',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#004a7b',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2005.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2005.png'
    ],
    twitter: '@AF_Football',
    location: {
      venue_id: 3713,
      name: 'Falcon Stadium',
      city: 'Colorado Springs',
      state: 'CO',
      zip: '80840',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 38.9969701,
      longitude: -104.8436165,
      elevation: '2024.875732',
      capacity: 46692,
      year_constructed: 1962,
      grass: false,
      dome: false
    }
  },
  {
    id: 2006,
    school: 'Akron',
    mascot: 'Zips',
    abbreviation: 'AKR',
    alt_name1: null,
    alt_name2: 'AKR',
    alt_name3: 'Akron',
    conference: 'Mid-American',
    division: 'East',
    color: '#00285e',
    alt_color: '#84754e',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2006.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2006.png'
    ],
    twitter: '@ZipsFB',
    location: {
      venue_id: 3768,
      name: 'Summa Field at InfoCision Stadium',
      city: 'Akron',
      state: 'OH',
      zip: '44399',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.0725534,
      longitude: -81.5083408,
      elevation: '321.2875061',
      capacity: 30000,
      year_constructed: 2009,
      grass: false,
      dome: false
    }
  },
  {
    id: 333,
    school: 'Alabama',
    mascot: 'Crimson Tide',
    abbreviation: 'ALA',
    alt_name1: null,
    alt_name2: 'ALA',
    alt_name3: 'Alabama',
    conference: 'SEC',
    division: 'West',
    color: '#690014',
    alt_color: '#f1f2f3',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/333.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/333.png'
    ],
    twitter: '@AlabamaFTBL',
    location: {
      venue_id: 3657,
      name: 'Bryant Denny Stadium',
      city: 'Tuscaloosa',
      state: 'AL',
      zip: '35487',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 33.2082752,
      longitude: -87.5503836,
      elevation: '70.05136108',
      capacity: 101821,
      year_constructed: 1929,
      grass: true,
      dome: false
    }
  },
  {
    id: 2026,
    school: 'Appalachian State',
    mascot: 'Mountaineers',
    abbreviation: 'APP',
    alt_name1: null,
    alt_name2: 'APP',
    alt_name3: 'Appalachian St',
    conference: 'Sun Belt',
    division: 'East',
    color: '#000000',
    alt_color: '#ffcd00',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2026.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2026.png'
    ],
    twitter: '@AppState_FB',
    location: {
      venue_id: 3792,
      name: 'Kidd Brewer Stadium',
      city: 'Boone',
      state: 'NC',
      zip: '28608',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 36.2114267,
      longitude: -81.6854278,
      elevation: '991.3414307',
      capacity: 30000,
      year_constructed: 1962,
      grass: false,
      dome: false
    }
  },
  {
    id: 12,
    school: 'Arizona',
    mascot: 'Wildcats',
    abbreviation: 'ARIZ',
    alt_name1: null,
    alt_name2: 'ARIZ',
    alt_name3: 'Arizona',
    conference: 'Pac-12',
    division: null,
    color: '#002449',
    alt_color: '#00205b',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/12.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/12.png'
    ],
    twitter: '@ArizonaFBall',
    location: {
      venue_id: 3619,
      name: 'Arizona Stadium',
      city: 'Tucson',
      state: 'AZ',
      zip: '85721',
      country_code: 'US',
      timezone: 'America/Phoenix',
      latitude: 32.2288054,
      longitude: -110.9488677,
      elevation: '742.1530151',
      capacity: 50782,
      year_constructed: 1928,
      grass: false,
      dome: false
    }
  },
  {
    id: 9,
    school: 'Arizona State',
    mascot: 'Sun Devils',
    abbreviation: 'ASU',
    alt_name1: null,
    alt_name2: 'ASU',
    alt_name3: 'Arizona State',
    conference: 'Pac-12',
    division: null,
    color: '#942139',
    alt_color: '#f1f2f3',
    logos: ['https://a.espncdn.com/i/teamlogos/ncaa/500/9.png', 'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/9.png'],
    twitter: '@ASUFootball',
    location: {
      venue_id: 3947,
      name: 'Sun Devil Stadium',
      city: 'Tempe',
      state: 'AZ',
      zip: '85287',
      country_code: 'US',
      timezone: 'America/Phoenix',
      latitude: 33.4264471,
      longitude: -111.9325005,
      elevation: '360.1569519',
      capacity: 65870,
      year_constructed: 1958,
      grass: true,
      dome: false
    }
  },
  {
    id: 8,
    school: 'Arkansas',
    mascot: 'Razorbacks',
    abbreviation: 'ARK',
    alt_name1: null,
    alt_name2: 'ARK',
    alt_name3: 'Arkansas',
    conference: 'SEC',
    division: 'West',
    color: '#9c1831',
    alt_color: '#000000',
    logos: ['https://a.espncdn.com/i/teamlogos/ncaa/500/8.png', 'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/8.png'],
    twitter: '@RazorbackFB',
    location: {
      venue_id: 3887,
      name: 'Donald W. Reynolds Razorback Stadium Frank Broyles Field',
      city: 'Fayetteville',
      state: 'AR',
      zip: '72702',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 36.0680662,
      longitude: -94.1789534,
      elevation: '403.7972412',
      capacity: 80000,
      year_constructed: 1938,
      grass: false,
      dome: false
    }
  },
  {
    id: 2032,
    school: 'Arkansas State',
    mascot: 'Red Wolves',
    abbreviation: 'ARST',
    alt_name1: null,
    alt_name2: 'ARST',
    alt_name3: 'Arkansas State',
    conference: 'Sun Belt',
    division: 'West',
    color: '#e81018',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2032.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2032.png'
    ],
    twitter: '@AStateFB',
    location: {
      venue_id: 3625,
      name: 'Centennial Bank Stadium',
      city: 'Jonesboro',
      state: 'AR',
      zip: '72404',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 35.8488977,
      longitude: -90.6677436,
      elevation: '89.52690887',
      capacity: 30382,
      year_constructed: 1974,
      grass: false,
      dome: false
    }
  },
  {
    id: 349,
    school: 'Army',
    mascot: 'Black Knights',
    abbreviation: 'ARMY',
    alt_name1: null,
    alt_name2: 'ARMY',
    alt_name3: 'Army',
    conference: 'FBS Independents',
    division: null,
    color: '#ce9c00',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/349.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/349.png'
    ],
    twitter: '@ArmyWP_Football',
    location: {
      venue_id: 3841,
      name: 'Blaik Field at Michie Stadium',
      city: 'West Point',
      state: 'NY',
      zip: '10996',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.3874924,
      longitude: -73.9640891,
      elevation: '99.21277618',
      capacity: 38000,
      year_constructed: 1924,
      grass: false,
      dome: false
    }
  },
  {
    id: 2,
    school: 'Auburn',
    mascot: 'Tigers',
    abbreviation: 'AUB',
    alt_name1: null,
    alt_name2: 'AUB',
    alt_name3: 'Auburn',
    conference: 'SEC',
    division: 'West',
    color: '#03244d',
    alt_color: '#f1f2f3',
    logos: ['https://a.espncdn.com/i/teamlogos/ncaa/500/2.png', 'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2.png'],
    twitter: '@AuburnFootball',
    location: {
      venue_id: 3785,
      name: 'Jordan-Hare Stadium',
      city: 'Auburn',
      state: 'AL',
      zip: '36849',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 32.6025532,
      longitude: -85.4897479,
      elevation: '201.1737976',
      capacity: 87451,
      year_constructed: 1939,
      grass: true,
      dome: false
    }
  },
  {
    id: 2050,
    school: 'Ball State',
    mascot: 'Cardinals',
    abbreviation: 'BALL',
    alt_name1: null,
    alt_name2: 'BALL',
    alt_name3: 'Ball State',
    conference: 'Mid-American',
    division: 'West',
    color: '#DA0000',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2050.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2050.png'
    ],
    twitter: '@BallStateFB',
    location: {
      venue_id: 3919,
      name: 'Scheumann Stadium',
      city: 'Muncie',
      state: 'IN',
      zip: '47306',
      country_code: 'US',
      timezone: 'America/Indiana/Indianapolis',
      latitude: 40.2159422,
      longitude: -85.4161148,
      elevation: '285.481781',
      capacity: 22500,
      year_constructed: 1967,
      grass: false,
      dome: false
    }
  },
  {
    id: 239,
    school: 'Baylor',
    mascot: 'Bears',
    abbreviation: 'BAY',
    alt_name1: null,
    alt_name2: 'BAY',
    alt_name3: 'Baylor',
    conference: 'Big 12',
    division: null,
    color: '#004834',
    alt_color: '#ffb81c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/239.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/239.png'
    ],
    twitter: '@BUFootball',
    location: {
      venue_id: 4727,
      name: 'McLane Stadium',
      city: 'Waco',
      state: 'TX',
      zip: '76704',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 31.5582015,
      longitude: -97.1156702,
      elevation: '117.0538101',
      capacity: 45140,
      year_constructed: 2014,
      grass: false,
      dome: false
    }
  },
  {
    id: 68,
    school: 'Boise State',
    mascot: 'Broncos',
    abbreviation: 'BSU',
    alt_name1: null,
    alt_name2: 'BSU',
    alt_name3: 'Boise State',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#09347A',
    alt_color: '#d8d9da',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/68.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/68.png'
    ],
    twitter: '@BroncoSportsFB',
    location: {
      venue_id: 3653,
      name: 'Albertsons Stadium',
      city: 'Boise',
      state: 'ID',
      zip: '83725',
      country_code: 'US',
      timezone: 'America/Boise',
      latitude: 43.6028839,
      longitude: -116.1958882,
      elevation: '823.2221069',
      capacity: 36387,
      year_constructed: 1970,
      grass: false,
      dome: false
    }
  },
  {
    id: 103,
    school: 'Boston College',
    mascot: 'Eagles',
    abbreviation: 'BC',
    alt_name1: null,
    alt_name2: 'BC',
    alt_name3: 'Boston College',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#88001a',
    alt_color: '#a39161',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/103.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/103.png'
    ],
    twitter: '@BCFootball',
    location: {
      venue_id: 3615,
      name: 'Alumni Stadium',
      city: 'Chestnut Hill',
      state: 'MA',
      zip: '2467 ',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 42.335104,
      longitude: -71.1664413,
      elevation: '39.82634354',
      capacity: 44500,
      year_constructed: 1957,
      grass: false,
      dome: false
    }
  },
  {
    id: 189,
    school: 'Bowling Green',
    mascot: 'Falcons',
    abbreviation: 'BGSU',
    alt_name1: 'Bowling Green State',
    alt_name2: 'BGSU',
    alt_name3: 'Bowling Green',
    conference: 'Mid-American',
    division: 'East',
    color: '#2b1000',
    alt_color: '#492000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/189.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/189.png'
    ],
    twitter: '@BG_Football',
    location: {
      venue_id: 3700,
      name: 'Doyt Perry Stadium',
      city: 'Bowling Green',
      state: 'OH',
      zip: '43403',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.3780114,
      longitude: -83.6225,
      elevation: '205.9057465',
      capacity: 24000,
      year_constructed: 1966,
      grass: false,
      dome: false
    }
  },
  {
    id: 2084,
    school: 'Buffalo',
    mascot: 'Bulls',
    abbreviation: 'BUFF',
    alt_name1: null,
    alt_name2: 'BUFF',
    alt_name3: 'Buffalo',
    conference: 'Mid-American',
    division: 'East',
    color: '#041A9B',
    alt_color: '#ebebeb',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2084.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2084.png'
    ],
    twitter: '@UBFootball',
    location: {
      venue_id: 3965,
      name: 'UB Stadium',
      city: 'Amherst',
      state: 'NY',
      zip: '14260',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 42.9991334,
      longitude: -78.7775113,
      elevation: '181.5759735',
      capacity: 30270,
      year_constructed: 1993,
      grass: false,
      dome: false
    }
  },
  {
    id: 252,
    school: 'BYU',
    mascot: 'Cougars',
    abbreviation: 'BYU',
    alt_name1: 'Brigham Young',
    alt_name2: 'BYU',
    alt_name3: 'BYU',
    conference: 'FBS Independents',
    division: null,
    color: '#001E4C',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/252.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/252.png'
    ],
    twitter: '@BYUFootball',
    location: {
      venue_id: 3801,
      name: 'LaVell Edwards Stadium',
      city: 'Provo',
      state: 'UT',
      zip: '84602',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 40.2575347,
      longitude: -111.6545247,
      elevation: '1412.097046',
      capacity: 63470,
      year_constructed: 1964,
      grass: true,
      dome: false
    }
  },
  {
    id: 25,
    school: 'California',
    mascot: 'Golden Bears',
    abbreviation: 'CAL',
    alt_name1: null,
    alt_name2: 'CAL',
    alt_name3: 'Cal',
    conference: 'Pac-12',
    division: null,
    color: '#031522',
    alt_color: '#ffc423',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/25.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/25.png'
    ],
    twitter: '@CalFootball',
    location: {
      venue_id: 3831,
      name: 'California Memorial Stadium',
      city: 'Berkeley',
      state: 'CA',
      zip: '94720',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 37.8710434,
      longitude: -122.2507729,
      elevation: '125.3108063',
      capacity: 62467,
      year_constructed: 1923,
      grass: false,
      dome: false
    }
  },
  {
    id: 2117,
    school: 'Central Michigan',
    mascot: 'Chippewas',
    abbreviation: 'CMU',
    alt_name1: null,
    alt_name2: 'CMU',
    alt_name3: 'Cent Michigan',
    conference: 'Mid-American',
    division: 'West',
    color: '#6a0032',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2117.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2117.png'
    ],
    twitter: '@CMU_Football',
    location: {
      venue_id: 3786,
      name: 'Kelly/Shorts Stadium',
      city: 'Mount Pleasant',
      state: 'MI',
      zip: '48859',
      country_code: 'US',
      timezone: 'America/Detroit',
      latitude: 43.5777313,
      longitude: -84.7709904,
      elevation: '240.9007721',
      capacity: 30255,
      year_constructed: 1972,
      grass: false,
      dome: false
    }
  },
  {
    id: 2429,
    school: 'Charlotte',
    mascot: '49ers',
    abbreviation: 'CHAR',
    alt_name1: null,
    alt_name2: 'CHAR',
    alt_name3: 'Charlotte',
    conference: 'Conference USA',
    division: null,
    color: '#ffffff',
    alt_color: '#cfab7a',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2429.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2429.png'
    ],
    twitter: '@CharlotteFTBL',
    location: {
      venue_id: 4418,
      name: 'Jerry Richardson Stadium',
      city: 'Charlotte',
      state: 'NC',
      zip: '28223',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.3105033,
      longitude: -80.7401194,
      elevation: '190.365509',
      capacity: 15300,
      year_constructed: 2013,
      grass: false,
      dome: false
    }
  },
  {
    id: 2132,
    school: 'Cincinnati',
    mascot: 'Bearcats',
    abbreviation: 'CIN',
    alt_name1: null,
    alt_name2: 'CIN',
    alt_name3: 'Cincinnati',
    conference: 'American Athletic',
    division: null,
    color: '#000000',
    alt_color: '#717073',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2132.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2132.png'
    ],
    twitter: '@GoBearcatsFB',
    location: {
      venue_id: 3854,
      name: 'Nippert Stadium',
      city: 'Cincinnati',
      state: 'OH',
      zip: '45221',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 39.1312495,
      longitude: -84.5161913,
      elevation: '230.0660553',
      capacity: 40000,
      year_constructed: 1915,
      grass: false,
      dome: false
    }
  },
  {
    id: 228,
    school: 'Clemson',
    mascot: 'Tigers',
    abbreviation: 'CLEM',
    alt_name1: null,
    alt_name2: 'CLEM',
    alt_name3: 'Clemson',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#F66733',
    alt_color: '#522d80',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/228.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/228.png'
    ],
    twitter: '@ClemsonFB',
    location: {
      venue_id: 3836,
      name: 'Memorial Stadium',
      city: 'Clemson',
      state: 'SC',
      zip: '29634',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 34.6787737,
      longitude: -82.8432428,
      elevation: '205.6452179',
      capacity: 81500,
      year_constructed: 1942,
      grass: true,
      dome: false
    }
  },
  {
    id: 324,
    school: 'Coastal Carolina',
    mascot: 'Chanticleers',
    abbreviation: 'CCU',
    alt_name1: null,
    alt_name2: 'CCU',
    alt_name3: 'C. Carolina',
    conference: 'Sun Belt',
    division: 'East',
    color: '#007073',
    alt_color: '#876447',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/324.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/324.png'
    ],
    twitter: '@CoastalFootball',
    location: {
      venue_id: 3654,
      name: 'Brooks Stadium',
      city: 'Conway',
      state: 'SC',
      zip: '29528',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 33.7928506,
      longitude: -79.0166946,
      elevation: '13.74894905',
      capacity: 21000,
      year_constructed: 2003,
      grass: false,
      dome: false
    }
  },
  {
    id: 38,
    school: 'Colorado',
    mascot: 'Buffaloes',
    abbreviation: 'COLO',
    alt_name1: null,
    alt_name2: 'COLO',
    alt_name3: 'Colorado',
    conference: 'Pac-12',
    division: null,
    color: '#d1c57e',
    alt_color: '#ffd200',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/38.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/38.png'
    ],
    twitter: '@CUBuffsFootball',
    location: {
      venue_id: 3726,
      name: 'Folsom Field',
      city: 'Boulder',
      state: 'CO',
      zip: '80309',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 40.0094746,
      longitude: -105.266905,
      elevation: '1634.041138',
      capacity: 50183,
      year_constructed: 1924,
      grass: true,
      dome: false
    }
  },
  {
    id: 36,
    school: 'Colorado State',
    mascot: 'Rams',
    abbreviation: 'CSU',
    alt_name1: null,
    alt_name2: 'CSU',
    alt_name3: 'Colorado State',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#004537',
    alt_color: '#ffc425',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/36.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/36.png'
    ],
    twitter: '@CSUFootball',
    location: {
      venue_id: 5388,
      name: 'Canvas Stadium',
      city: 'Fort Collins',
      state: 'CO',
      zip: '80521',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 40.570015,
      longitude: -105.088435,
      elevation: '1528.4',
      capacity: 36500,
      year_constructed: 2017,
      grass: false,
      dome: false
    }
  },
  {
    id: 41,
    school: 'Connecticut',
    mascot: 'Huskies',
    abbreviation: 'CONN',
    alt_name1: null,
    alt_name2: 'CONN',
    alt_name3: 'UConn',
    conference: 'FBS Independents',
    division: null,
    color: '#001d40',
    alt_color: '#f1f2f3',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/41.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/41.png'
    ],
    twitter: '@UConnFootball',
    location: {
      venue_id: 3892,
      name: 'Rentschler Field',
      city: 'East Hartford',
      state: 'CT',
      zip: '6118 ',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.7595675,
      longitude: -72.6187728,
      elevation: '6.515614986',
      capacity: 40000,
      year_constructed: 2003,
      grass: true,
      dome: false
    }
  },
  {
    id: 150,
    school: 'Duke',
    mascot: 'Blue Devils',
    abbreviation: 'DUKE',
    alt_name1: null,
    alt_name2: 'DUKE',
    alt_name3: 'Duke',
    conference: 'ACC',
    division: 'Coastal',
    color: '#001A57',
    alt_color: '#f1f2f3',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/150.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/150.png'
    ],
    twitter: '@DukeFootball',
    location: {
      venue_id: 3982,
      name: 'Wallace Wade Stadium',
      city: 'Durham',
      state: 'NC',
      zip: '27708',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.9953688,
      longitude: -78.9417564,
      elevation: '103.0028152',
      capacity: 40004,
      year_constructed: 1929,
      grass: true,
      dome: false
    }
  },
  {
    id: 151,
    school: 'East Carolina',
    mascot: 'Pirates',
    abbreviation: 'ECU',
    alt_name1: null,
    alt_name2: 'ECU',
    alt_name3: 'ECU',
    conference: 'American Athletic',
    division: null,
    color: '#4b1869',
    alt_color: '#f0907b',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/151.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/151.png'
    ],
    twitter: '@ECUPiratesFB',
    location: {
      venue_id: 3699,
      name: 'Dowdy-Ficklen Stadium',
      city: 'Greenville',
      state: 'NC',
      zip: '27858',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.5968486,
      longitude: -77.364564,
      elevation: '19.0564537',
      capacity: 50000,
      year_constructed: 1963,
      grass: true,
      dome: false
    }
  },
  {
    id: 2199,
    school: 'Eastern Michigan',
    mascot: 'Eagles',
    abbreviation: 'EMU',
    alt_name1: null,
    alt_name2: 'EMU',
    alt_name3: 'E Michigan',
    conference: 'Mid-American',
    division: 'West',
    color: '#00331b',
    alt_color: '#f0f0f0',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2199.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2199.png'
    ],
    twitter: '@EMUFB',
    location: {
      venue_id: 3912,
      name: 'Rynearson Stadium',
      city: 'Ypsilanti',
      state: 'MI',
      zip: '48197',
      country_code: 'US',
      timezone: 'America/Detroit',
      latitude: 42.256126,
      longitude: -83.647285,
      elevation: '241.248764',
      capacity: 30200,
      year_constructed: 1969,
      grass: false,
      dome: false
    }
  },
  {
    id: 57,
    school: 'Florida',
    mascot: 'Gators',
    abbreviation: 'FLA',
    alt_name1: null,
    alt_name2: 'FLA',
    alt_name3: 'Florida',
    conference: 'SEC',
    division: 'East',
    color: '#0021A5',
    alt_color: '#0021a5',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/57.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/57.png'
    ],
    twitter: '@GatorsFB',
    location: {
      venue_id: 3634,
      name: 'Ben Hill Griffin Stadium',
      city: 'Gainesville',
      state: 'FL',
      zip: '32653',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 29.6499357,
      longitude: -82.3485788,
      elevation: '44.97250748',
      capacity: 88548,
      year_constructed: 1930,
      grass: true,
      dome: false
    }
  },
  {
    id: 2226,
    school: 'Florida Atlantic',
    mascot: 'Owls',
    abbreviation: 'FAU',
    alt_name1: null,
    alt_name2: 'FAU',
    alt_name3: 'FAU',
    conference: 'Conference USA',
    division: null,
    color: '#004B85',
    alt_color: '#bb2f4c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2226.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2226.png'
    ],
    twitter: '@FAUFootball',
    location: {
      venue_id: 3715,
      name: 'FAU Stadium',
      city: 'Boca Raton',
      state: 'FL',
      zip: '33499',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 26.3763763,
      longitude: -80.1015759,
      elevation: '3.613628864',
      capacity: 29419,
      year_constructed: 2011,
      grass: false,
      dome: false
    }
  },
  {
    id: 2229,
    school: 'Florida International',
    mascot: 'Golden Panthers',
    abbreviation: 'FIU',
    alt_name1: 'Florida Intl',
    alt_name2: 'FIU',
    alt_name3: 'FIU',
    conference: 'Conference USA',
    division: null,
    color: '#001538',
    alt_color: '#c5960c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2229.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2229.png'
    ],
    twitter: '@FIUFootball',
    location: {
      venue_id: 218,
      name: 'FIU Stadium',
      city: 'Miami',
      state: 'FL',
      zip: '33199',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 25.7525014,
      longitude: -80.3778912,
      elevation: '1.718581796',
      capacity: 20000,
      year_constructed: 1995,
      grass: false,
      dome: false
    }
  },
  {
    id: 52,
    school: 'Florida State',
    mascot: 'Seminoles',
    abbreviation: 'FSU',
    alt_name1: null,
    alt_name2: 'FSU',
    alt_name3: 'FSU',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#782F40',
    alt_color: '#ceb888',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/52.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/52.png'
    ],
    twitter: '@FSUFootball',
    location: {
      venue_id: 3697,
      name: 'Bobby Bowden Field at Doak Campbell Stadium',
      city: 'Tallahassee',
      state: 'FL',
      zip: '32306',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 30.4381692,
      longitude: -84.3044032,
      elevation: '19.55942535',
      capacity: 79560,
      year_constructed: 1950,
      grass: true,
      dome: false
    }
  },
  {
    id: 278,
    school: 'Fresno State',
    mascot: 'Bulldogs',
    abbreviation: 'FRES',
    alt_name1: null,
    alt_name2: 'FRES',
    alt_name3: 'Fresno State',
    conference: 'Mountain West',
    division: 'West',
    color: '#c41230',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/278.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/278.png'
    ],
    twitter: '@FresnoStateFB',
    location: {
      venue_id: 3660,
      name: 'Bulldog Stadium',
      city: 'Fresno',
      state: 'CA',
      zip: '93740',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 36.8143527,
      longitude: -119.7580092,
      elevation: '102.564064',
      capacity: 41031,
      year_constructed: 1980,
      grass: false,
      dome: false
    }
  },
  {
    id: 61,
    school: 'Georgia',
    mascot: 'Bulldogs',
    abbreviation: 'UGA',
    alt_name1: null,
    alt_name2: 'UGA',
    alt_name3: 'Georgia',
    conference: 'SEC',
    division: 'East',
    color: '#CC0000',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/61.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/61.png'
    ],
    twitter: '@GeorgiaFootball',
    location: {
      venue_id: 3917,
      name: 'Sanford Stadium',
      city: 'Athens',
      state: 'GA',
      zip: '30602',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 33.9498197,
      longitude: -83.3733813,
      elevation: '186.1120758',
      capacity: 92746,
      year_constructed: 1929,
      grass: true,
      dome: false
    }
  },
  {
    id: 290,
    school: 'Georgia Southern',
    mascot: 'Eagles',
    abbreviation: 'GASO',
    alt_name1: null,
    alt_name2: 'GASO',
    alt_name3: 'Ga Southern',
    conference: 'Sun Belt',
    division: 'East',
    color: '#003775',
    alt_color: '#f0f0f0',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/290.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/290.png'
    ],
    twitter: '@GSAthletics_FB',
    location: {
      venue_id: 3608,
      name: 'Paulson Stadium',
      city: 'Statesboro',
      state: 'GA',
      zip: '30460',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 32.4122471,
      longitude: -81.7849296,
      elevation: '61.25376892',
      capacity: 25000,
      year_constructed: 1984,
      grass: false,
      dome: false
    }
  },
  {
    id: 2247,
    school: 'Georgia State',
    mascot: 'Panthers',
    abbreviation: 'GAST',
    alt_name1: null,
    alt_name2: 'GAST',
    alt_name3: 'Georgia State',
    conference: 'Sun Belt',
    division: 'East',
    color: '#1e539a',
    alt_color: '#ebebeb',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2247.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2247.png'
    ],
    twitter: '@GeorgiaStateFB',
    location: {
      venue_id: 4246,
      name: 'Georgia State Stadium',
      city: 'Atlanta',
      state: 'GA',
      zip: '30315',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 33.735267,
      longitude: -84.389949,
      elevation: '287',
      capacity: 25000,
      year_constructed: 2017,
      grass: false,
      dome: false
    }
  },
  {
    id: 59,
    school: 'Georgia Tech',
    mascot: 'Yellow Jackets',
    abbreviation: 'GT',
    alt_name1: null,
    alt_name2: 'GT',
    alt_name3: 'Georgia Tech',
    conference: 'ACC',
    division: 'Coastal',
    color: '#00223e',
    alt_color: '#002c56',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/59.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/59.png'
    ],
    twitter: '@GeorgiaTechFB',
    location: {
      venue_id: 721,
      name: 'Bobby Dodd Stadium at Historic Grant Field',
      city: 'Atlanta',
      state: 'GA',
      zip: '30332',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 33.7724449,
      longitude: -84.3928054,
      elevation: '279.300415',
      capacity: 55000,
      year_constructed: 1913,
      grass: false,
      dome: false
    }
  },
  {
    id: 62,
    school: "Hawai'i",
    mascot: 'Rainbow Warriors',
    abbreviation: 'HAW',
    alt_name1: null,
    alt_name2: 'HAW',
    alt_name3: "Hawai'i",
    conference: 'Mountain West',
    division: 'West',
    color: '#003420',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/62.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/62.png'
    ],
    twitter: '@HawaiiFootball',
    location: {
      venue_id: 7220,
      name: 'Clarence T.C. Ching Athletics Complex',
      city: 'Honolulu',
      state: 'HI',
      zip: '96822',
      country_code: null,
      timezone: null,
      latitude: 21.294294,
      longitude: -157.819338,
      elevation: '20',
      capacity: 9000,
      year_constructed: null,
      grass: true,
      dome: false
    }
  },
  {
    id: 248,
    school: 'Houston',
    mascot: 'Cougars',
    abbreviation: 'HOU',
    alt_name1: null,
    alt_name2: 'HOU',
    alt_name3: 'Houston',
    conference: 'American Athletic',
    division: null,
    color: '#C90822',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/248.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/248.png'
    ],
    twitter: '@UHCougarFB',
    location: {
      venue_id: 4728,
      name: "John O'Quinn Field at TDECU Stadium",
      city: 'Houston',
      state: 'TX',
      zip: '77004',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 29.7219885,
      longitude: -95.3491623,
      elevation: '9.57440567',
      capacity: 40000,
      year_constructed: 2014,
      grass: false,
      dome: false
    }
  },
  {
    id: 356,
    school: 'Illinois',
    mascot: 'Fighting Illini',
    abbreviation: 'ILL',
    alt_name1: null,
    alt_name2: 'ILL',
    alt_name3: 'Illinois',
    conference: 'Big Ten',
    division: 'West',
    color: '#f77329',
    alt_color: '#fa6300',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/356.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/356.png'
    ],
    twitter: '@IlliniFootball',
    location: {
      venue_id: 3832,
      name: 'Memorial Stadium',
      city: 'Champaign',
      state: 'IL',
      zip: '61820',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 40.0993268,
      longitude: -88.2359569,
      elevation: '223.2471619',
      capacity: 60670,
      year_constructed: 1923,
      grass: false,
      dome: false
    }
  },
  {
    id: 84,
    school: 'Indiana',
    mascot: 'Hoosiers',
    abbreviation: 'IND',
    alt_name1: null,
    alt_name2: 'IND',
    alt_name3: 'Indiana',
    conference: 'Big Ten',
    division: 'East',
    color: '#7D110C',
    alt_color: '#eeedeb',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/84.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/84.png'
    ],
    twitter: '@IndianaFootball',
    location: {
      venue_id: 3830,
      name: 'Memorial Stadium',
      city: 'Bloomington',
      state: 'IN',
      zip: '47408',
      country_code: 'US',
      timezone: 'America/Indiana/Indianapolis',
      latitude: 39.1808959,
      longitude: -86.5256217,
      elevation: '231.6500854',
      capacity: 52656,
      year_constructed: 1960,
      grass: false,
      dome: false
    }
  },
  {
    id: 2294,
    school: 'Iowa',
    mascot: 'Hawkeyes',
    abbreviation: 'IOWA',
    alt_name1: null,
    alt_name2: 'IOWA',
    alt_name3: 'Iowa',
    conference: 'Big Ten',
    division: 'West',
    color: '#000000',
    alt_color: '#ffe100',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2294.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2294.png'
    ],
    twitter: '@HawkeyeFootball',
    location: {
      venue_id: 3793,
      name: 'Kinnick Stadium',
      city: 'Iowa City',
      state: 'IA',
      zip: '52242',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 41.6586045,
      longitude: -91.5510829,
      elevation: '220.6357727',
      capacity: 69250,
      year_constructed: 1929,
      grass: false,
      dome: false
    }
  },
  {
    id: 66,
    school: 'Iowa State',
    mascot: 'Cyclones',
    abbreviation: 'ISU',
    alt_name1: null,
    alt_name2: 'ISU',
    alt_name3: 'Iowa State',
    conference: 'Big 12',
    division: null,
    color: '#660015',
    alt_color: '#FDCA2F',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/66.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/66.png'
    ],
    twitter: '@CycloneFB',
    location: {
      venue_id: 3772,
      name: 'Jack Trice Stadium',
      city: 'Ames',
      state: 'IA',
      zip: '50011',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 42.0139977,
      longitude: -93.6357716,
      elevation: '274.9135437',
      capacity: 61500,
      year_constructed: 1975,
      grass: true,
      dome: false
    }
  },
  {
    id: 256,
    school: 'James Madison',
    mascot: 'Dukes',
    abbreviation: 'JMU',
    alt_name1: null,
    alt_name2: 'JMU',
    alt_name3: 'JMU',
    conference: 'Sun Belt',
    division: 'East',
    color: '#450084',
    alt_color: '#cbb778',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/256.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/256.png'
    ],
    twitter: '@JMUFootball',
    location: {
      venue_id: 3651,
      name: 'Bridgeforth Stadium',
      city: 'Harrisonburg',
      state: 'VA',
      zip: '22807',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.4352919,
      longitude: -78.8729349,
      elevation: '399.303009',
      capacity: 24877,
      year_constructed: 1975,
      grass: false,
      dome: false
    }
  },
  {
    id: 2305,
    school: 'Kansas',
    mascot: 'Jayhawks',
    abbreviation: 'KU',
    alt_name1: null,
    alt_name2: 'KU',
    alt_name3: 'Kansas',
    conference: 'Big 12',
    division: null,
    color: '#0022B4',
    alt_color: '#e8000d',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2305.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2305.png'
    ],
    twitter: '@KU_Football',
    location: {
      venue_id: 3833,
      name: 'Memorial Stadium',
      city: 'Lawrence',
      state: 'KS',
      zip: '66045',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 38.9629418,
      longitude: -95.2463686,
      elevation: '274.855957',
      capacity: 47000,
      year_constructed: 1921,
      grass: false,
      dome: false
    }
  },
  {
    id: 2306,
    school: 'Kansas State',
    mascot: 'Wildcats',
    abbreviation: 'KSU',
    alt_name1: null,
    alt_name2: 'KSU',
    alt_name3: 'Kansas State',
    conference: 'Big 12',
    division: null,
    color: '#633194',
    alt_color: '#e7d2ad',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2306.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2306.png'
    ],
    twitter: '@KStateFB',
    location: {
      venue_id: 3636,
      name: 'Bill Snyder Family Football Stadium',
      city: 'Manhattan',
      state: 'KS',
      zip: '66502',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 39.2020096,
      longitude: -96.5938395,
      elevation: '328.0574646',
      capacity: 50000,
      year_constructed: 1968,
      grass: false,
      dome: false
    }
  },
  {
    id: 2309,
    school: 'Kent State',
    mascot: 'Golden Flashes',
    abbreviation: 'KENT',
    alt_name1: null,
    alt_name2: 'KENT',
    alt_name3: 'Kent State',
    conference: 'Mid-American',
    division: 'East',
    color: '#002445',
    alt_color: '#f0b510',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2309.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2309.png'
    ],
    twitter: '@KentStFootball',
    location: {
      venue_id: 3696,
      name: 'Dix Stadium',
      city: 'Kent',
      state: 'OH',
      zip: '44242',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.1390935,
      longitude: -81.31346,
      elevation: '321.3641663',
      capacity: 25319,
      year_constructed: 1969,
      grass: false,
      dome: false
    }
  },
  {
    id: 96,
    school: 'Kentucky',
    mascot: 'Wildcats',
    abbreviation: 'UK',
    alt_name1: null,
    alt_name2: 'UK',
    alt_name3: 'Kentucky',
    conference: 'SEC',
    division: 'East',
    color: '#005DAA',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/96.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/96.png'
    ],
    twitter: '@UKFootball',
    location: {
      venue_id: 3683,
      name: 'Commonwealth Stadium',
      city: 'Lexington',
      state: 'KY',
      zip: '40506',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.0220905,
      longitude: -84.5053408,
      elevation: '307.4248962',
      capacity: 61000,
      year_constructed: 1973,
      grass: false,
      dome: false
    }
  },
  {
    id: 2335,
    school: 'Liberty',
    mascot: 'Flames',
    abbreviation: 'LIB',
    alt_name1: null,
    alt_name2: 'LIB',
    alt_name3: 'Liberty',
    conference: 'FBS Independents',
    division: null,
    color: '#071740',
    alt_color: '#a61f21',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2335.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2335.png'
    ],
    twitter: '@LibertyFootball',
    location: {
      venue_id: 3992,
      name: 'Williams Stadium',
      city: 'Lynchburg',
      state: 'VA',
      zip: '24502',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 37.3544305,
      longitude: -79.1750239,
      elevation: '272.9237061',
      capacity: 25000,
      year_constructed: 1989,
      grass: false,
      dome: false
    }
  },
  {
    id: 309,
    school: 'Louisiana',
    mascot: "Ragin' Cajuns",
    abbreviation: 'ULL',
    alt_name1: 'UL Lafayette',
    alt_name2: 'ULL',
    alt_name3: 'Louisiana',
    conference: 'Sun Belt',
    division: 'West',
    color: '#ce2842',
    alt_color: null,
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/309.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/309.png'
    ],
    twitter: '@RaginCajunsFB',
    location: {
      venue_id: 3666,
      name: 'Cajun Field',
      city: 'Lafayette',
      state: 'LA',
      zip: '70506',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 30.2158434,
      longitude: -92.0417371,
      elevation: '3.556508303',
      capacity: 41426,
      year_constructed: 1971,
      grass: false,
      dome: false
    }
  },
  {
    id: 2433,
    school: 'Louisiana Monroe',
    mascot: 'Warhawks',
    abbreviation: 'ULM',
    alt_name1: 'La.-Monroe',
    alt_name2: 'ULM',
    alt_name3: 'UL Monroe',
    conference: 'Sun Belt',
    division: 'West',
    color: '#231F20',
    alt_color: '#b18445',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2433.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2433.png'
    ],
    twitter: '@ULM_FB',
    location: {
      venue_id: 3817,
      name: 'Malone Stadium',
      city: 'Monroe',
      state: 'LA',
      zip: '71209',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 32.5308089,
      longitude: -92.0660602,
      elevation: '22.998209',
      capacity: 30427,
      year_constructed: 1978,
      grass: false,
      dome: false
    }
  },
  {
    id: 2348,
    school: 'Louisiana Tech',
    mascot: 'Bulldogs',
    abbreviation: 'LT',
    alt_name1: null,
    alt_name2: 'LT',
    alt_name3: 'LA Tech',
    conference: 'Conference USA',
    division: null,
    color: '#002d65',
    alt_color: '#d3313a',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2348.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2348.png'
    ],
    twitter: '@LATechFB',
    location: {
      venue_id: 3776,
      name: 'Joe Aillet Stadium',
      city: 'Ruston',
      state: 'LA',
      zip: '71272',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 32.5321487,
      longitude: -92.6560019,
      elevation: '72.87982941',
      capacity: 28019,
      year_constructed: 1968,
      grass: false,
      dome: false
    }
  },
  {
    id: 97,
    school: 'Louisville',
    mascot: 'Cardinals',
    abbreviation: 'LOU',
    alt_name1: null,
    alt_name2: 'LOU',
    alt_name3: 'Louisville',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#ad000a',
    alt_color: '#cccccc',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/97.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/97.png'
    ],
    twitter: '@LouisvilleFB',
    location: {
      venue_id: 3873,
      name: "Papa John's Cardinal Stadium",
      city: 'Louisville',
      state: 'KY',
      zip: '40292',
      country_code: 'US',
      timezone: 'America/Kentucky/Louisville',
      latitude: 38.2057621,
      longitude: -85.7588141,
      elevation: '138.5615997',
      capacity: 60000,
      year_constructed: 1998,
      grass: false,
      dome: false
    }
  },
  {
    id: 99,
    school: 'LSU',
    mascot: 'Tigers',
    abbreviation: 'LSU',
    alt_name1: 'Louisiana State',
    alt_name2: 'LSU',
    alt_name3: 'LSU',
    conference: 'SEC',
    division: 'West',
    color: '#fdd023',
    alt_color: '#2b0d57',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/99.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/99.png'
    ],
    twitter: '@LSUFootball',
    location: {
      venue_id: 3958,
      name: 'Tiger Stadium',
      city: 'Baton Rouge',
      state: 'LA',
      zip: '70803',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 30.412035,
      longitude: -91.1838163,
      elevation: '7.640734673',
      capacity: 102321,
      year_constructed: 1924,
      grass: true,
      dome: false
    }
  },
  {
    id: 276,
    school: 'Marshall',
    mascot: 'Thundering Herd',
    abbreviation: 'MRSH',
    alt_name1: null,
    alt_name2: 'MRSH',
    alt_name3: 'Marshall',
    conference: 'Sun Belt',
    division: 'East',
    color: '#00ae42',
    alt_color: '#be854c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/276.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/276.png'
    ],
    twitter: '@HerdFB',
    location: {
      venue_id: 3775,
      name: 'Joan C. Edwards Stadium',
      city: 'Huntington',
      state: 'WV',
      zip: '25755',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.4247042,
      longitude: -82.4214412,
      elevation: '163.7328186',
      capacity: 38016,
      year_constructed: 1991,
      grass: false,
      dome: false
    }
  },
  {
    id: 120,
    school: 'Maryland',
    mascot: 'Terrapins',
    abbreviation: 'MD',
    alt_name1: null,
    alt_name2: 'MD',
    alt_name3: 'Maryland',
    conference: 'Big Ten',
    division: 'East',
    color: '#D5002B',
    alt_color: '#ffcd00',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/120.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/120.png'
    ],
    twitter: '@TerpsFootball',
    location: {
      venue_id: 3665,
      name: 'Maryland Stadium',
      city: 'College Park',
      state: 'MD',
      zip: '20742',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.9903332,
      longitude: -76.9473792,
      elevation: '32.68748093',
      capacity: 51802,
      year_constructed: 1950,
      grass: false,
      dome: false
    }
  },
  {
    id: 235,
    school: 'Memphis',
    mascot: 'Tigers',
    abbreviation: 'MEM',
    alt_name1: null,
    alt_name2: 'MEM',
    alt_name3: 'Memphis',
    conference: 'American Athletic',
    division: null,
    color: '#003087',
    alt_color: '#898d8d',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/235.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/235.png'
    ],
    twitter: '@MemphisFB',
    location: {
      venue_id: 3805,
      name: 'Liberty Bowl Memorial Stadium',
      city: 'Memphis',
      state: 'TN',
      zip: '38152',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 35.1209429,
      longitude: -89.977147,
      elevation: '90.62445831',
      capacity: 58325,
      year_constructed: 1965,
      grass: false,
      dome: false
    }
  },
  {
    id: 2390,
    school: 'Miami',
    mascot: 'Hurricanes',
    abbreviation: 'MIAMI',
    alt_name1: 'Miami (FL)',
    alt_name2: 'MIAMI',
    alt_name3: 'Miami',
    conference: 'ACC',
    division: 'Coastal',
    color: '#004325',
    alt_color: '#f0f0f0',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2390.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2390.png'
    ],
    twitter: '@CanesFootball',
    location: {
      venue_id: 3948,
      name: 'Hard Rock Stadium',
      city: 'Miami Gardens',
      state: 'FL',
      zip: '33299',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 25.9579665,
      longitude: -80.2388604,
      elevation: '2.624486446',
      capacity: 64767,
      year_constructed: 1987,
      grass: false,
      dome: false
    }
  },
  {
    id: 193,
    school: 'Miami (OH)',
    mascot: 'RedHawks',
    abbreviation: 'M-OH',
    alt_name1: null,
    alt_name2: 'M-OH',
    alt_name3: 'Miami (OH)',
    conference: 'Mid-American',
    division: 'East',
    color: '#a4000c',
    alt_color: '#f0f0f0',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/193.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/193.png'
    ],
    twitter: '@MiamiOHFootball',
    location: {
      venue_id: 3996,
      name: 'Yager Stadium',
      city: 'Oxford',
      state: 'OH',
      zip: '45056',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 39.5197009,
      longitude: -84.7330255,
      elevation: '242.2643585',
      capacity: 24286,
      year_constructed: 1983,
      grass: false,
      dome: false
    }
  },
  {
    id: 130,
    school: 'Michigan',
    mascot: 'Wolverines',
    abbreviation: 'MICH',
    alt_name1: null,
    alt_name2: 'MICH',
    alt_name3: 'Michigan',
    conference: 'Big Ten',
    division: 'East',
    color: '#00274c',
    alt_color: '#FFCB05',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/130.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/130.png'
    ],
    twitter: '@UMichFootball',
    location: {
      venue_id: 3558,
      name: 'Michigan Stadium',
      city: 'Ann Arbor',
      state: 'MI',
      zip: '48109',
      country_code: 'US',
      timezone: 'America/Detroit',
      latitude: 42.2658365,
      longitude: -83.7486956,
      elevation: '255.5500031',
      capacity: 107601,
      year_constructed: 1927,
      grass: false,
      dome: false
    }
  },
  {
    id: 127,
    school: 'Michigan State',
    mascot: 'Spartans',
    abbreviation: 'MSU',
    alt_name1: null,
    alt_name2: 'MSU',
    alt_name3: 'Michigan State',
    conference: 'Big Ten',
    division: 'East',
    color: '#18453B',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/127.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/127.png'
    ],
    twitter: '@MSU_Football',
    location: {
      venue_id: 3936,
      name: 'Spartan Stadium',
      city: 'East Lansing',
      state: 'MI',
      zip: '48824',
      country_code: 'US',
      timezone: 'America/Detroit',
      latitude: 42.7281474,
      longitude: -84.4848526,
      elevation: '254.8794861',
      capacity: 75005,
      year_constructed: 1923,
      grass: true,
      dome: false
    }
  },
  {
    id: 2393,
    school: 'Middle Tennessee',
    mascot: 'Blue Raiders',
    abbreviation: 'MTSU',
    alt_name1: 'Middle Tennessee State',
    alt_name2: 'MTSU',
    alt_name3: 'Mid Tennessee',
    conference: 'Conference USA',
    division: null,
    color: '#0079C2',
    alt_color: '#c3c5c8',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2393.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2393.png'
    ],
    twitter: '@MT_FB',
    location: {
      venue_id: 3725,
      name: 'Johnny Red"" Floyd Stadium""',
      city: 'Murfreesboro',
      state: 'TN',
      zip: '37132',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 35.8511463,
      longitude: -86.3681649,
      elevation: '191.7279816',
      capacity: 30788,
      year_constructed: 1933,
      grass: false,
      dome: false
    }
  },
  {
    id: 135,
    school: 'Minnesota',
    mascot: 'Golden Gophers',
    abbreviation: 'MINN',
    alt_name1: null,
    alt_name2: 'MINN',
    alt_name3: 'Minnesota',
    conference: 'Big Ten',
    division: 'West',
    color: '#981a31',
    alt_color: '#981a31',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/135.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/135.png'
    ],
    twitter: '@GopherFootball',
    location: {
      venue_id: 3953,
      name: 'TCF Bank Stadium',
      city: 'Minneapolis',
      state: 'MN',
      zip: '55488',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 44.976525,
      longitude: -93.2245462,
      elevation: '254.3902283',
      capacity: 50850,
      year_constructed: 2009,
      grass: false,
      dome: false
    }
  },
  {
    id: 344,
    school: 'Mississippi State',
    mascot: 'Bulldogs',
    abbreviation: 'MSST',
    alt_name1: null,
    alt_name2: 'MSST',
    alt_name3: 'Miss St',
    conference: 'SEC',
    division: 'West',
    color: '#762123',
    alt_color: '#c8c8c8',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/344.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/344.png'
    ],
    twitter: '@HailStateFB',
    location: {
      venue_id: 3693,
      name: 'Davis Wade Stadium',
      city: 'Starkville',
      state: 'MS',
      zip: '39762',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 33.4563403,
      longitude: -88.7933652,
      elevation: '110.2742081',
      capacity: 61337,
      year_constructed: 1914,
      grass: true,
      dome: false
    }
  },
  {
    id: 142,
    school: 'Missouri',
    mascot: 'Tigers',
    abbreviation: 'MIZ',
    alt_name1: null,
    alt_name2: 'MIZ',
    alt_name3: 'Missouri',
    conference: 'SEC',
    division: 'East',
    color: '#000000',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/142.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/142.png'
    ],
    twitter: '@MizzouFootball',
    location: {
      venue_id: 3838,
      name: 'Faurot Field',
      city: 'Columbia',
      state: 'MO',
      zip: '65211',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 38.9358491,
      longitude: -92.3332009,
      elevation: '212.6865692',
      capacity: 71168,
      year_constructed: 1927,
      grass: false,
      dome: false
    }
  },
  {
    id: 2426,
    school: 'Navy',
    mascot: 'Midshipmen',
    abbreviation: 'NAVY',
    alt_name1: null,
    alt_name2: 'NAVY',
    alt_name3: 'Navy',
    conference: 'American Athletic',
    division: null,
    color: '#131630',
    alt_color: '#b6a77c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2426.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2426.png'
    ],
    twitter: '@NavyFB',
    location: {
      venue_id: 3852,
      name: 'Navy-Marine Corps Memorial Stadium',
      city: 'Annapolis',
      state: 'MD',
      zip: '21402',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.9846985,
      longitude: -76.5076335,
      elevation: '10.74990082',
      capacity: 34000,
      year_constructed: 1959,
      grass: false,
      dome: false
    }
  },
  {
    id: 152,
    school: 'NC State',
    mascot: 'Wolfpack',
    abbreviation: 'NCST',
    alt_name1: 'North Carolina St.',
    alt_name2: 'NCST',
    alt_name3: 'NC State',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#EF1216',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/152.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/152.png'
    ],
    twitter: '@PackFootball',
    location: {
      venue_id: 3670,
      name: 'Carter-Finley Stadium',
      city: 'Raleigh',
      state: 'NC',
      zip: '27695',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.8008001,
      longitude: -78.7195655,
      elevation: '117.0089951',
      capacity: 58000,
      year_constructed: 1966,
      grass: true,
      dome: false
    }
  },
  {
    id: 158,
    school: 'Nebraska',
    mascot: 'Cornhuskers',
    abbreviation: 'NEB',
    alt_name1: null,
    alt_name2: 'NEB',
    alt_name3: 'Nebraska',
    conference: 'Big Ten',
    division: 'West',
    color: '#F20017',
    alt_color: '#f5f1e7',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/158.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/158.png'
    ],
    twitter: '@HuskerFBNation',
    location: {
      venue_id: 3834,
      name: 'Memorial Stadium',
      city: 'Lincoln',
      state: 'NE',
      zip: '68588',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 40.820682,
      longitude: -96.705594,
      elevation: '354.9318848',
      capacity: 85458,
      year_constructed: 1923,
      grass: false,
      dome: false
    }
  },
  {
    id: 2440,
    school: 'Nevada',
    mascot: 'Wolf Pack',
    abbreviation: 'NEV',
    alt_name1: null,
    alt_name2: 'NEV',
    alt_name3: 'Nevada',
    conference: 'Mountain West',
    division: 'West',
    color: '#153E5F',
    alt_color: '#8e9295',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2440.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2440.png'
    ],
    twitter: '@NevadaFootball',
    location: {
      venue_id: 3816,
      name: 'Mackay Stadium',
      city: 'Reno',
      state: 'NV',
      zip: '89557',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 39.5469459,
      longitude: -119.8175435,
      elevation: '1406.57605',
      capacity: 27000,
      year_constructed: 1966,
      grass: false,
      dome: false
    }
  },
  {
    id: 167,
    school: 'New Mexico',
    mascot: 'Lobos',
    abbreviation: 'UNM',
    alt_name1: null,
    alt_name2: 'UNM',
    alt_name3: 'New Mexico',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#000000',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/167.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/167.png'
    ],
    twitter: '@UNMLoboFB',
    location: {
      venue_id: 3971,
      name: 'University Stadium',
      city: 'Albuquerque',
      state: 'NM',
      zip: '87131',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 35.0669479,
      longitude: -106.6283225,
      elevation: '1554.471924',
      capacity: 39224,
      year_constructed: 1960,
      grass: false,
      dome: false
    }
  },
  {
    id: 166,
    school: 'New Mexico State',
    mascot: 'Aggies',
    abbreviation: 'NMSU',
    alt_name1: null,
    alt_name2: 'NMSU',
    alt_name3: 'New Mexico St',
    conference: 'FBS Independents',
    division: null,
    color: '#891216',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/166.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/166.png'
    ],
    twitter: '@NMStateFootball',
    location: {
      venue_id: 3601,
      name: 'Aggie Memorial Stadium',
      city: 'Las Cruces',
      state: 'NM',
      zip: '88003',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 32.2796202,
      longitude: -106.7411148,
      elevation: '1208.201294',
      capacity: 28853,
      year_constructed: 1978,
      grass: false,
      dome: false
    }
  },
  {
    id: 153,
    school: 'North Carolina',
    mascot: 'Tar Heels',
    abbreviation: 'UNC',
    alt_name1: null,
    alt_name2: 'UNC',
    alt_name3: 'UNC',
    conference: 'ACC',
    division: 'Coastal',
    color: '#99bfe5',
    alt_color: '#13294b',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/153.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/153.png'
    ],
    twitter: '@UNCFootball',
    location: {
      venue_id: 3787,
      name: 'Kenan Memorial Stadium',
      city: 'Chapel Hill',
      state: 'NC',
      zip: '27515',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.9069294,
      longitude: -79.0478889,
      elevation: '127.7569809',
      capacity: 50500,
      year_constructed: 1927,
      grass: false,
      dome: false
    }
  },
  {
    id: 2459,
    school: 'Northern Illinois',
    mascot: 'Huskies',
    abbreviation: 'NIU',
    alt_name1: null,
    alt_name2: 'NIU',
    alt_name3: 'N Illinois',
    conference: 'Mid-American',
    division: 'West',
    color: '#F1122C',
    alt_color: '#cc0000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2459.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2459.png'
    ],
    twitter: '@NIU_Football',
    location: {
      venue_id: 3764,
      name: 'Brigham Field at Huskie Stadium',
      city: 'DeKalb',
      state: 'IL',
      zip: '60115',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 41.9339586,
      longitude: -88.7778357,
      elevation: '267.4530029',
      capacity: 23595,
      year_constructed: 1965,
      grass: false,
      dome: false
    }
  },
  {
    id: 249,
    school: 'North Texas',
    mascot: 'Mean Green',
    abbreviation: 'UNT',
    alt_name1: null,
    alt_name2: 'UNT',
    alt_name3: 'North Texas',
    conference: 'Conference USA',
    division: null,
    color: '#00853D',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/249.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/249.png'
    ],
    twitter: '@MeanGreenFB',
    location: {
      venue_id: 3825,
      name: 'Apogee Stadium',
      city: 'Denton',
      state: 'TX',
      zip: '76210',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 33.203899,
      longitude: -97.159245,
      elevation: '198.6871948',
      capacity: 30850,
      year_constructed: 2011,
      grass: false,
      dome: false
    }
  },
  {
    id: 77,
    school: 'Northwestern',
    mascot: 'Wildcats',
    abbreviation: 'NW',
    alt_name1: null,
    alt_name2: 'NW',
    alt_name3: 'Northwestern',
    conference: 'Big Ten',
    division: 'West',
    color: '#372286',
    alt_color: '#d6cac1',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/77.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/77.png'
    ],
    twitter: '@NUFBFamily',
    location: {
      venue_id: 3911,
      name: 'Ryan Field',
      city: 'Evanston',
      state: 'IL',
      zip: '60208',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 42.0653994,
      longitude: -87.6924751,
      elevation: '181.2376099',
      capacity: 47130,
      year_constructed: 1926,
      grass: true,
      dome: false
    }
  },
  {
    id: 87,
    school: 'Notre Dame',
    mascot: 'Fighting Irish',
    abbreviation: 'ND',
    alt_name1: null,
    alt_name2: 'ND',
    alt_name3: 'Notre Dame',
    conference: 'Big Ten',
    division: null,
    color: '#00122b',
    alt_color: '#ae9142',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/87.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/87.png'
    ],
    twitter: '@NDFootball',
    location: {
      venue_id: 3855,
      name: 'Notre Dame Stadium',
      city: 'Notre Dame',
      state: 'IN',
      zip: '46556',
      country_code: 'US',
      timezone: 'America/Indiana/Indianapolis',
      latitude: 41.698378,
      longitude: -86.2339425,
      elevation: '222.645401',
      capacity: 80795,
      year_constructed: 1930,
      grass: false,
      dome: false
    }
  },
  {
    id: 195,
    school: 'Ohio',
    mascot: 'Bobcats',
    abbreviation: 'OHIO',
    alt_name1: null,
    alt_name2: 'OHIO',
    alt_name3: 'Ohio',
    conference: 'Mid-American',
    division: 'East',
    color: '#295A29',
    alt_color: '#e4bb85',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/195.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/195.png'
    ],
    twitter: '@OhioFootball',
    location: {
      venue_id: 3876,
      name: 'Peden Stadium',
      city: 'Athens',
      state: 'OH',
      zip: '45701',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 39.3212794,
      longitude: -82.1034315,
      elevation: '194.230896',
      capacity: 24000,
      year_constructed: 1929,
      grass: false,
      dome: false
    }
  },
  {
    id: 194,
    school: 'Ohio State',
    mascot: 'Buckeyes',
    abbreviation: 'OSU',
    alt_name1: null,
    alt_name2: 'OSU',
    alt_name3: 'OSU',
    conference: 'Big Ten',
    division: 'East',
    color: '#DE3121',
    alt_color: '#666666',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/194.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/194.png'
    ],
    twitter: '@OhioStateFB',
    location: {
      venue_id: 3861,
      name: 'Ohio Stadium',
      city: 'Columbus',
      state: 'OH',
      zip: '43210',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 40.0016447,
      longitude: -83.0197266,
      elevation: '216.6770325',
      capacity: 102780,
      year_constructed: 1922,
      grass: false,
      dome: false
    }
  },
  {
    id: 201,
    school: 'Oklahoma',
    mascot: 'Sooners',
    abbreviation: 'OKLA',
    alt_name1: null,
    alt_name2: 'OKLA',
    alt_name3: 'Oklahoma',
    conference: 'Big 12',
    division: null,
    color: '#7b0000',
    alt_color: '#cccccc',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/201.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/201.png'
    ],
    twitter: '@OU_Football',
    location: {
      venue_id: 3835,
      name: 'Gaylord Family Oklahoma Memorial Stadium',
      city: 'Norman',
      state: 'OK',
      zip: '73019',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 35.2058537,
      longitude: -97.4423145,
      elevation: '355.0962524',
      capacity: 86112,
      year_constructed: 1925,
      grass: true,
      dome: false
    }
  },
  {
    id: 197,
    school: 'Oklahoma State',
    mascot: 'Cowboys',
    abbreviation: 'OKST',
    alt_name1: null,
    alt_name2: 'OKST',
    alt_name3: 'Oklahoma State',
    conference: 'Big 12',
    division: null,
    color: '#FF6500',
    alt_color: '#ff9900',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/197.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/197.png'
    ],
    twitter: '@CowboyFB',
    location: {
      venue_id: 3646,
      name: 'Boone Pickens Stadium',
      city: 'Stillwater',
      state: 'OK',
      zip: '74078',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 36.125673,
      longitude: -97.066513,
      elevation: '276.4808655',
      capacity: 55509,
      year_constructed: 1919,
      grass: false,
      dome: false
    }
  },
  {
    id: 295,
    school: 'Old Dominion',
    mascot: 'Monarchs',
    abbreviation: 'ODU',
    alt_name1: null,
    alt_name2: 'ODU',
    alt_name3: 'Old Dominion',
    conference: 'Sun Belt',
    division: 'East',
    color: '#00507d',
    alt_color: '#a1d2f1',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/295.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/295.png'
    ],
    twitter: '@ODUFootball',
    location: {
      venue_id: 3918,
      name: 'Foreman Field',
      city: 'Norfolk',
      state: 'VA',
      zip: '23551',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 36.8889533,
      longitude: -76.3049175,
      elevation: '3.147987843',
      capacity: 20118,
      year_constructed: 1936,
      grass: false,
      dome: false
    }
  },
  {
    id: 145,
    school: 'Ole Miss',
    mascot: 'Rebels',
    abbreviation: 'MISS',
    alt_name1: null,
    alt_name2: 'MISS',
    alt_name3: 'Ole Miss',
    conference: 'SEC',
    division: 'West',
    color: '#001148',
    alt_color: '#00205b',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/145.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/145.png'
    ],
    twitter: '@OleMissFB',
    location: {
      venue_id: 3974,
      name: 'Vaught-Hemingway Stadium',
      city: 'Oxford',
      state: 'MS',
      zip: '38677',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 34.3619837,
      longitude: -89.5342076,
      elevation: '146.8517151',
      capacity: 64038,
      year_constructed: 1915,
      grass: true,
      dome: false
    }
  },
  {
    id: 2483,
    school: 'Oregon',
    mascot: 'Ducks',
    abbreviation: 'ORE',
    alt_name1: null,
    alt_name2: 'ORE',
    alt_name3: 'Oregon',
    conference: 'Pac-12',
    division: null,
    color: '#044520',
    alt_color: '#fee123',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2483.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2483.png'
    ],
    twitter: '@OregonFootball',
    location: {
      venue_id: 3626,
      name: 'Autzen Stadium',
      city: 'Eugene',
      state: 'OR',
      zip: '97401',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 44.0582712,
      longitude: -123.0684883,
      elevation: '127.4198303',
      capacity: 54000,
      year_constructed: 1967,
      grass: false,
      dome: false
    }
  },
  {
    id: 204,
    school: 'Oregon State',
    mascot: 'Beavers',
    abbreviation: 'ORST',
    alt_name1: null,
    alt_name2: 'ORST',
    alt_name3: 'Oregon State',
    conference: 'Pac-12',
    division: null,
    color: '#c34500',
    alt_color: '#dea076',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/204.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/204.png'
    ],
    twitter: '@BeaverFootball',
    location: {
      venue_id: 3893,
      name: 'Reser Stadium',
      city: 'Corvallis',
      state: 'OR',
      zip: '97331',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 44.5594559,
      longitude: -123.2814341,
      elevation: '66.90744781',
      capacity: 43363,
      year_constructed: 1953,
      grass: false,
      dome: false
    }
  },
  {
    id: 213,
    school: 'Penn State',
    mascot: 'Nittany Lions',
    abbreviation: 'PSU',
    alt_name1: null,
    alt_name2: 'PSU',
    alt_name3: 'Penn State',
    conference: 'Big Ten',
    division: 'East',
    color: '#00265D',
    alt_color: '#002e5c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/213.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/213.png'
    ],
    twitter: '@PennStateFball',
    location: {
      venue_id: 3632,
      name: 'Beaver Stadium',
      city: 'University Park',
      state: 'PA',
      zip: '16802',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 40.8121958,
      longitude: -77.8561023,
      elevation: '354.1551514',
      capacity: 106572,
      year_constructed: 1960,
      grass: true,
      dome: false
    }
  },
  {
    id: 221,
    school: 'Pittsburgh',
    mascot: 'Panthers',
    abbreviation: 'PITT',
    alt_name1: 'Pitt',
    alt_name2: 'PITT',
    alt_name3: 'Pitt',
    conference: 'ACC',
    division: 'Coastal',
    color: '#003263',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/221.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/221.png'
    ],
    twitter: '@Pitt_FB',
    location: {
      venue_id: 3752,
      name: 'Heinz Field',
      city: 'Pittsburgh',
      state: 'PA',
      zip: '15260',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 40.4467648,
      longitude: -80.0157603,
      elevation: '220.5887146',
      capacity: 68400,
      year_constructed: 2001,
      grass: true,
      dome: false
    }
  },
  {
    id: 2509,
    school: 'Purdue',
    mascot: 'Boilermakers',
    abbreviation: 'PUR',
    alt_name1: null,
    alt_name2: 'PUR',
    alt_name3: 'Purdue',
    conference: 'Big Ten',
    division: 'West',
    color: '#B89D29',
    alt_color: '#a4a9ad',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2509.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2509.png'
    ],
    twitter: '@BoilerFootball',
    location: {
      venue_id: 3907,
      name: 'Ross-Ade Stadium',
      city: 'West Lafayette',
      state: 'IN',
      zip: '47907',
      country_code: 'US',
      timezone: 'America/Indiana/Indianapolis',
      latitude: 40.4352253,
      longitude: -86.9186843,
      elevation: '197.5337677',
      capacity: 57282,
      year_constructed: 1924,
      grass: true,
      dome: false
    }
  },
  {
    id: 242,
    school: 'Rice',
    mascot: 'Owls',
    abbreviation: 'RICE',
    alt_name1: null,
    alt_name2: 'RICE',
    alt_name3: 'Rice',
    conference: 'Conference USA',
    division: null,
    color: '#003D7D',
    alt_color: '#d1d5d8',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/242.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/242.png'
    ],
    twitter: '@RiceFootball',
    location: {
      venue_id: 3895,
      name: 'Rice Stadium',
      city: 'Houston',
      state: 'TX',
      zip: '77251',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 29.7163297,
      longitude: -95.4093261,
      elevation: '7.148301601',
      capacity: 47000,
      year_constructed: 1950,
      grass: false,
      dome: false
    }
  },
  {
    id: 164,
    school: 'Rutgers',
    mascot: 'Scarlet Knights',
    abbreviation: 'RUTG',
    alt_name1: null,
    alt_name2: 'RUTG',
    alt_name3: 'Rutgers',
    conference: 'Big Ten',
    division: 'East',
    color: '#d21034',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/164.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/164.png'
    ],
    twitter: '@Rfootball',
    location: {
      venue_id: 6577,
      name: 'SHI Stadium',
      city: 'Piscataway',
      state: 'NJ',
      zip: '08854',
      country_code: 'US',
      timezone: null,
      latitude: 40.5462553,
      longitude: -74.4660408,
      elevation: null,
      capacity: 52454,
      year_constructed: null,
      grass: false,
      dome: false
    }
  },
  {
    id: 21,
    school: 'San Diego State',
    mascot: 'Aztecs',
    abbreviation: 'SDSU',
    alt_name1: null,
    alt_name2: 'SDSU',
    alt_name3: 'San Diego State',
    conference: 'Mountain West',
    division: 'West',
    color: '#BF2C37',
    alt_color: '#a8996e',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/21.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/21.png'
    ],
    twitter: '@AztecFB',
    location: {
      venue_id: 7311,
      name: 'Snapdragon Stadium',
      city: 'San Diego',
      state: 'CA',
      zip: '92093',
      country_code: 'US',
      timezone: 'America/Las_Angeles',
      latitude: 32.784444,
      longitude: -117.122833,
      elevation: '62',
      capacity: 35000,
      year_constructed: 2022,
      grass: true,
      dome: false
    }
  },
  {
    id: 23,
    school: 'San José State',
    mascot: 'Spartans',
    abbreviation: 'SJSU',
    alt_name1: 'San Jose St.',
    alt_name2: 'SJSU',
    alt_name3: 'San Jose State',
    conference: 'Mountain West',
    division: 'West',
    color: '#005893',
    alt_color: '#fdba31',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/23.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/23.png'
    ],
    twitter: '@SanJoseStateFB',
    location: {
      venue_id: 3935,
      name: 'Spartan Stadium',
      city: 'San Jose',
      state: 'CA',
      zip: '95112',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 37.3196675,
      longitude: -121.8682962,
      elevation: '28.30708694',
      capacity: 30456,
      year_constructed: 1933,
      grass: false,
      dome: false
    }
  },
  {
    id: 2567,
    school: 'SMU',
    mascot: 'Mustangs',
    abbreviation: 'SMU',
    alt_name1: 'Southern Methodist',
    alt_name2: 'SMU',
    alt_name3: 'SMU',
    conference: 'American Athletic',
    division: null,
    color: '#E32F38',
    alt_color: '#ecdcb9',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2567.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2567.png'
    ],
    twitter: '@SMUFB',
    location: {
      venue_id: 3735,
      name: 'Gerald J. Ford Stadium',
      city: 'University Park',
      state: 'TX',
      zip: '75275',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 32.8377223,
      longitude: -96.7827859,
      elevation: '169.4058075',
      capacity: 32000,
      year_constructed: 2000,
      grass: false,
      dome: false
    }
  },
  {
    id: 6,
    school: 'South Alabama',
    mascot: 'Jaguars',
    abbreviation: 'USA',
    alt_name1: null,
    alt_name2: 'USA',
    alt_name3: 'South Alabama',
    conference: 'Sun Belt',
    division: 'West',
    color: '#003E7E',
    alt_color: null,
    logos: ['https://a.espncdn.com/i/teamlogos/ncaa/500/6.png', 'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/6.png'],
    twitter: '@SouthAlabamaFB',
    location: {
      venue_id: 6526,
      name: 'Hancock Whitney Stadium',
      city: 'Mobile',
      state: 'AL',
      zip: '85329',
      country_code: 'US',
      timezone: null,
      latitude: 30.6943566,
      longitude: -88.0430541,
      elevation: null,
      capacity: 25450,
      year_constructed: null,
      grass: false,
      dome: false
    }
  },
  {
    id: 2579,
    school: 'South Carolina',
    mascot: 'Gamecocks',
    abbreviation: 'SC',
    alt_name1: null,
    alt_name2: 'SC',
    alt_name3: 'South Carolina',
    conference: 'SEC',
    division: 'East',
    color: '#670010',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2579.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2579.png'
    ],
    twitter: '@GamecockFB',
    location: {
      venue_id: 3994,
      name: 'Williams-Brice Stadium',
      city: 'Columbia',
      state: 'SC',
      zip: '29208',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 33.9730239,
      longitude: -81.0191726,
      elevation: '60.54548645',
      capacity: 80250,
      year_constructed: 1934,
      grass: true,
      dome: false
    }
  },
  {
    id: 2572,
    school: 'Southern Mississippi',
    mascot: 'Golden Eagles',
    abbreviation: 'USM',
    alt_name1: null,
    alt_name2: 'USM',
    alt_name3: 'Southern Miss',
    conference: 'Sun Belt',
    division: 'West',
    color: '#FFAA3C',
    alt_color: '#ffc423',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2572.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2572.png'
    ],
    twitter: '@SouthernMissFB',
    location: {
      venue_id: 3815,
      name: 'M. M. Roberts Stadium',
      city: 'Hattiesburg',
      state: 'MS',
      zip: '39402',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 31.3289321,
      longitude: -89.3318118,
      elevation: '64.52726746',
      capacity: 36000,
      year_constructed: 1932,
      grass: false,
      dome: false
    }
  },
  {
    id: 58,
    school: 'South Florida',
    mascot: 'Bulls',
    abbreviation: 'USF',
    alt_name1: null,
    alt_name2: 'USF',
    alt_name3: 'USF',
    conference: 'American Athletic',
    division: null,
    color: '#004A36',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/58.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/58.png'
    ],
    twitter: '@USFFootball',
    location: {
      venue_id: 3886,
      name: 'Raymond James Stadium',
      city: 'Tampa',
      state: 'FL',
      zip: '33620',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 27.9758691,
      longitude: -82.5033344,
      elevation: '11.07972908',
      capacity: 65828,
      year_constructed: 1998,
      grass: true,
      dome: false
    }
  },
  {
    id: 24,
    school: 'Stanford',
    mascot: 'Cardinal',
    abbreviation: 'STAN',
    alt_name1: null,
    alt_name2: 'STAN',
    alt_name3: 'Stanford',
    conference: 'Pac-12',
    division: null,
    color: '#A80532',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/24.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/24.png'
    ],
    twitter: '@StanfordFball',
    location: {
      venue_id: 3940,
      name: 'Stanford Stadium',
      city: 'Stanford',
      state: 'CA',
      zip: '94305',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 37.4345556,
      longitude: -122.1611271,
      elevation: '18.38425636',
      capacity: 50424,
      year_constructed: 2006,
      grass: true,
      dome: false
    }
  },
  {
    id: 183,
    school: 'Syracuse',
    mascot: 'Orange',
    abbreviation: 'SYR',
    alt_name1: null,
    alt_name2: 'SYR',
    alt_name3: 'Syracuse',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#F37321',
    alt_color: '#0d1d37',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/183.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/183.png'
    ],
    twitter: '@CuseFootball',
    location: {
      venue_id: 1964,
      name: 'Carrier Dome',
      city: 'Syracuse',
      state: 'NY',
      zip: '13244',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 43.0362269,
      longitude: -76.1363161,
      elevation: '162.6989746',
      capacity: 49057,
      year_constructed: 1980,
      grass: false,
      dome: true
    }
  },
  {
    id: 2628,
    school: 'TCU',
    mascot: 'Horned Frogs',
    abbreviation: 'TCU',
    alt_name1: 'Texas Christian',
    alt_name2: 'TCU',
    alt_name3: 'TCU',
    conference: 'Big 12',
    division: null,
    color: '#3C377D',
    alt_color: '#f1f2f3',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2628.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2628.png'
    ],
    twitter: '@TCUFootball',
    location: {
      venue_id: 3616,
      name: 'Amon G. Carter Stadium',
      city: 'Fort Worth',
      state: 'TX',
      zip: '76129',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 32.7096604,
      longitude: -97.3680835,
      elevation: '185.4387054',
      capacity: 46000,
      year_constructed: 1929,
      grass: true,
      dome: false
    }
  },
  {
    id: 218,
    school: 'Temple',
    mascot: 'Owls',
    abbreviation: 'TEM',
    alt_name1: null,
    alt_name2: 'TEM',
    alt_name3: 'Temple',
    conference: 'American Athletic',
    division: null,
    color: '#A80532',
    alt_color: '#a7a9ac',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/218.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/218.png'
    ],
    twitter: '@Temple_FB',
    location: {
      venue_id: 3806,
      name: 'Lincoln Financial Field',
      city: 'Philadelphia',
      state: 'PA',
      zip: '19122',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 39.9007995,
      longitude: -75.1675414,
      elevation: '3.198706865',
      capacity: 69879,
      year_constructed: 2003,
      grass: false,
      dome: false
    }
  },
  {
    id: 2633,
    school: 'Tennessee',
    mascot: 'Volunteers',
    abbreviation: 'TENN',
    alt_name1: null,
    alt_name2: 'TENN',
    alt_name3: 'Tennessee',
    conference: 'SEC',
    division: 'East',
    color: '#EE9627',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2633.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2633.png'
    ],
    twitter: '@Vol_Football',
    location: {
      venue_id: 3853,
      name: 'Neyland Stadium',
      city: 'Knoxville',
      state: 'TN',
      zip: '37996',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 35.9550131,
      longitude: -83.9250128,
      elevation: '271.9517212',
      capacity: 102455,
      year_constructed: 1921,
      grass: true,
      dome: false
    }
  },
  {
    id: 251,
    school: 'Texas',
    mascot: 'Longhorns',
    abbreviation: 'TEX',
    alt_name1: null,
    alt_name2: 'TEX',
    alt_name3: 'Texas',
    conference: 'Big 12',
    division: null,
    color: '#EE7524',
    alt_color: '#f0f0f0',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/251.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/251.png'
    ],
    twitter: '@TexasFootball',
    location: {
      venue_id: 3910,
      name: 'Darrell K Royal-Texas Memorial Stadium',
      city: 'Austin',
      state: 'TX',
      zip: '78712',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 30.2836813,
      longitude: -97.7325345,
      elevation: '160.3089447',
      capacity: 100119,
      year_constructed: 1924,
      grass: false,
      dome: false
    }
  },
  {
    id: 245,
    school: 'Texas A&M',
    mascot: 'Aggies',
    abbreviation: 'TA&M',
    alt_name1: null,
    alt_name2: 'TA&M',
    alt_name3: 'Texas A&M',
    conference: 'SEC',
    division: 'West',
    color: '#5C0025',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/245.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/245.png'
    ],
    twitter: '@AggieFootball',
    location: {
      venue_id: 3795,
      name: 'Kyle Field',
      city: 'College Station',
      state: 'TX',
      zip: '77843',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 30.6098891,
      longitude: -96.3403828,
      elevation: '108.824585',
      capacity: 102733,
      year_constructed: 1927,
      grass: true,
      dome: false
    }
  },
  {
    id: 326,
    school: 'Texas State',
    mascot: 'Bobcats',
    abbreviation: 'TXST',
    alt_name1: null,
    alt_name2: 'TXST',
    alt_name3: 'Texas State',
    conference: 'Sun Belt',
    division: 'West',
    color: '#4e1719',
    alt_color: '#b4975a',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/326.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/326.png'
    ],
    twitter: '@TXStateFootball',
    location: {
      venue_id: 3644,
      name: 'Jim Wacker Field at Bobcat Stadium',
      city: 'San Marcos',
      state: 'TX',
      zip: '78666',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 29.8910008,
      longitude: -97.9255735,
      elevation: '177.8676453',
      capacity: 30000,
      year_constructed: 1981,
      grass: false,
      dome: false
    }
  },
  {
    id: 2641,
    school: 'Texas Tech',
    mascot: 'Red Raiders',
    abbreviation: 'TTU',
    alt_name1: null,
    alt_name2: 'TTU',
    alt_name3: 'Texas Tech',
    conference: 'Big 12',
    division: null,
    color: '#C80025',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2641.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2641.png'
    ],
    twitter: '@TexasTechFB',
    location: {
      venue_id: 3784,
      name: 'Jones AT&T Stadium',
      city: 'Lubbock',
      state: 'TX',
      zip: '79409',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 33.5910518,
      longitude: -101.8728824,
      elevation: '980.7632446',
      capacity: 60454,
      year_constructed: 1947,
      grass: false,
      dome: false
    }
  },
  {
    id: 2649,
    school: 'Toledo',
    mascot: 'Rockets',
    abbreviation: 'TOL',
    alt_name1: null,
    alt_name2: 'TOL',
    alt_name3: 'Toledo',
    conference: 'Mid-American',
    division: 'West',
    color: '#ffffff',
    alt_color: '#ffd51d',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2649.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2649.png'
    ],
    twitter: '@ToledoFB',
    location: {
      venue_id: 3739,
      name: 'Glass Bowl',
      city: 'Toledo',
      state: 'OH',
      zip: '43606',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 41.6574777,
      longitude: -83.6135652,
      elevation: '181.4839783',
      capacity: 26038,
      year_constructed: 1937,
      grass: false,
      dome: false
    }
  },
  {
    id: 2653,
    school: 'Troy',
    mascot: 'Trojans',
    abbreviation: 'TROY',
    alt_name1: null,
    alt_name2: 'TROY',
    alt_name3: 'Troy',
    conference: 'Sun Belt',
    division: 'West',
    color: '#AE0210',
    alt_color: '#88898c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2653.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2653.png'
    ],
    twitter: '@TroyTrojansFB',
    location: {
      venue_id: 3975,
      name: 'Veterans Memorial Stadium',
      city: 'Troy',
      state: 'AL',
      zip: '36082',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 31.7995565,
      longitude: -85.9518328,
      elevation: '154.7746277',
      capacity: 30000,
      year_constructed: 1950,
      grass: false,
      dome: false
    }
  },
  {
    id: 2655,
    school: 'Tulane',
    mascot: 'Green Wave',
    abbreviation: 'TULN',
    alt_name1: null,
    alt_name2: 'TULN',
    alt_name3: 'Tulane',
    conference: 'American Athletic',
    division: null,
    color: '#005837',
    alt_color: '#3baf29',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2655.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2655.png'
    ],
    twitter: '@GreenWaveFB',
    location: {
      venue_id: 4729,
      name: 'Benson Field at Yulman Stadium',
      city: 'New Orleans',
      state: 'LA',
      zip: '70118',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 29.944616,
      longitude: -90.116692,
      elevation: '0.0000000000000000000000000000000000000118',
      capacity: 30000,
      year_constructed: 2014,
      grass: false,
      dome: false
    }
  },
  {
    id: 202,
    school: 'Tulsa',
    mascot: 'Golden Hurricane',
    abbreviation: 'TLSA',
    alt_name1: null,
    alt_name2: 'TLSA',
    alt_name3: 'Tulsa',
    conference: 'American Athletic',
    division: null,
    color: '#004371',
    alt_color: '#ee3b33',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/202.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/202.png'
    ],
    twitter: '@TulsaFootball',
    location: {
      venue_id: 3674,
      name: 'Skelly Field at H. A. Chapman Stadium',
      city: 'Tulsa',
      state: 'OK',
      zip: '74194',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 36.1489175,
      longitude: -95.9437853,
      elevation: '235.5928345',
      capacity: 30000,
      year_constructed: 1930,
      grass: false,
      dome: false
    }
  },
  {
    id: 5,
    school: 'UAB',
    mascot: 'Blazers',
    abbreviation: 'UAB',
    alt_name1: 'Alabama-Birmingham',
    alt_name2: 'UAB',
    alt_name3: 'UAB',
    conference: 'Conference USA',
    division: null,
    color: '#003b28',
    alt_color: '#ffc845',
    logos: ['https://a.espncdn.com/i/teamlogos/ncaa/500/5.png', 'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/5.png'],
    twitter: '@UAB_FB',
    location: {
      venue_id: 7221,
      name: 'Protective Stadium',
      city: 'Birmingham',
      state: 'AL',
      zip: '35203',
      country_code: 'US',
      timezone: null,
      latitude: 33.5206824,
      longitude: -86.8024326,
      elevation: null,
      capacity: 0,
      year_constructed: null,
      grass: false,
      dome: false
    }
  },
  {
    id: 2116,
    school: 'UCF',
    mascot: 'Knights',
    abbreviation: 'UCF',
    alt_name1: 'Central Florida',
    alt_name2: 'UCF',
    alt_name3: 'UCF',
    conference: 'American Athletic',
    division: null,
    color: '#000000',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2116.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2116.png'
    ],
    twitter: '@UCF_Football',
    location: {
      venue_id: 3652,
      name: 'Bright House Networks Stadium',
      city: 'Orlando',
      state: 'FL',
      zip: '32816',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 28.6079765,
      longitude: -81.1927233,
      elevation: '20.94113731',
      capacity: 44206,
      year_constructed: 2007,
      grass: true,
      dome: false
    }
  },
  {
    id: 26,
    school: 'UCLA',
    mascot: 'Bruins',
    abbreviation: 'UCLA',
    alt_name1: null,
    alt_name2: 'UCLA',
    alt_name3: 'UCLA',
    conference: 'Pac-12',
    division: null,
    color: '#005C8E',
    alt_color: '#ffc72c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/26.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/26.png'
    ],
    twitter: '@UCLAFootball',
    location: {
      venue_id: 1056,
      name: 'Rose Bowl',
      city: 'Pasadena',
      state: 'CA',
      zip: '90095',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 34.1613284,
      longitude: -118.1676462,
      elevation: '245.1772461',
      capacity: 88565,
      year_constructed: 1922,
      grass: true,
      dome: false
    }
  },
  {
    id: 113,
    school: 'UMass',
    mascot: 'Minutemen',
    abbreviation: 'UMASS',
    alt_name1: null,
    alt_name2: 'UMASS',
    alt_name3: 'UMass',
    conference: 'FBS Independents',
    division: null,
    color: '#880007',
    alt_color: null,
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/113.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/113.png'
    ],
    twitter: '@UMassFootball',
    location: {
      venue_id: 3985,
      name: 'Warren McGuirk Alumni Stadium',
      city: 'Hadley',
      state: 'MA',
      zip: '1003 ',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 42.3773244,
      longitude: -72.5360595,
      elevation: '46.58664703',
      capacity: 17000,
      year_constructed: 1965,
      grass: false,
      dome: false
    }
  },
  {
    id: 2439,
    school: 'UNLV',
    mascot: 'Rebels',
    abbreviation: 'UNLV',
    alt_name1: 'Nevada-Las Vegas',
    alt_name2: 'UNLV',
    alt_name3: 'UNLV',
    conference: 'Mountain West',
    division: 'West',
    color: '#000000',
    alt_color: '#c2c4c6',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2439.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2439.png'
    ],
    twitter: '@UNLVFootball',
    location: {
      venue_id: 6501,
      name: 'Allegiant Stadium',
      city: 'Las Vegas',
      state: 'NV',
      zip: '89154',
      country_code: 'US',
      timezone: null,
      latitude: 36.1672559,
      longitude: -115.1485163,
      elevation: null,
      capacity: 65000,
      year_constructed: null,
      grass: false,
      dome: true
    }
  },
  {
    id: 30,
    school: 'USC',
    mascot: 'Trojans',
    abbreviation: 'USC',
    alt_name1: null,
    alt_name2: 'USC',
    alt_name3: 'USC',
    conference: 'Pac-12',
    division: null,
    color: '#ffc72c',
    alt_color: '#ae2531',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/30.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/30.png'
    ],
    twitter: '@uscfb',
    location: {
      venue_id: 477,
      name: 'Los Angeles Memorial Coliseum',
      city: 'Los Angeles',
      state: 'CA',
      zip: '90089',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 34.014167,
      longitude: -118.287778,
      elevation: '42.50374222',
      capacity: 77500,
      year_constructed: 1923,
      grass: true,
      dome: false
    }
  },
  {
    id: 254,
    school: 'Utah',
    mascot: 'Utes',
    abbreviation: 'UTAH',
    alt_name1: null,
    alt_name2: 'UTAH',
    alt_name3: 'Utah',
    conference: 'Pac-12',
    division: null,
    color: '#890012',
    alt_color: '#7e8083',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/254.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/254.png'
    ],
    twitter: '@Utah_Football',
    location: {
      venue_id: 587,
      name: 'Rice-Eccles Stadium',
      city: 'Salt Lake City',
      state: 'UT',
      zip: '84112',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 40.7599724,
      longitude: -111.8488255,
      elevation: '1411.543579',
      capacity: 45807,
      year_constructed: 1998,
      grass: false,
      dome: false
    }
  },
  {
    id: 328,
    school: 'Utah State',
    mascot: 'Aggies',
    abbreviation: 'USU',
    alt_name1: null,
    alt_name2: 'USU',
    alt_name3: 'Utah State',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#003263',
    alt_color: '#949ca1',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/328.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/328.png'
    ],
    twitter: '@USUFootball',
    location: {
      venue_id: 3905,
      name: 'Romney Stadium',
      city: 'Logan',
      state: 'UT',
      zip: '84322',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 41.7517394,
      longitude: -111.8116007,
      elevation: '1427.914429',
      capacity: 25513,
      year_constructed: 1968,
      grass: false,
      dome: false
    }
  },
  {
    id: 2638,
    school: 'UTEP',
    mascot: 'Miners',
    abbreviation: 'UTEP',
    alt_name1: 'Texas-El Paso',
    alt_name2: 'UTEP',
    alt_name3: 'UTEP',
    conference: 'Conference USA',
    division: null,
    color: '#ff8200',
    alt_color: '#041e42',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2638.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2638.png'
    ],
    twitter: '@UTEPFB',
    location: {
      venue_id: 3946,
      name: 'Sun Bowl Stadium',
      city: 'El Paso',
      state: 'TX',
      zip: '79968',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 31.7733353,
      longitude: -106.507957,
      elevation: '1183.697876',
      capacity: 51500,
      year_constructed: 1963,
      grass: false,
      dome: false
    }
  },
  {
    id: 2636,
    school: 'UT San Antonio',
    mascot: 'Roadrunners',
    abbreviation: 'UTSA',
    alt_name1: 'Texas-San Antonio',
    alt_name2: 'UTSA',
    alt_name3: 'UTSA',
    conference: 'Conference USA',
    division: null,
    color: '#002A5C',
    alt_color: '#f47321',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2636.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2636.png'
    ],
    twitter: null,
    location: {
      venue_id: 3604,
      name: 'Alamodome',
      city: 'San Antonio',
      state: 'TX',
      zip: '78299',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 29.4169834,
      longitude: -98.4788143,
      elevation: '201.5526886',
      capacity: 36582,
      year_constructed: 1993,
      grass: false,
      dome: true
    }
  },
  {
    id: 238,
    school: 'Vanderbilt',
    mascot: 'Commodores',
    abbreviation: 'VAN',
    alt_name1: null,
    alt_name2: 'VAN',
    alt_name3: 'Vanderbilt',
    conference: 'SEC',
    division: 'East',
    color: '#000000',
    alt_color: '#231f20',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/238.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/238.png'
    ],
    twitter: '@VandyFootball',
    location: {
      venue_id: 3973,
      name: 'Vanderbilt Stadium',
      city: 'Nashville',
      state: 'TN',
      zip: '37212',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 36.1440455,
      longitude: -86.8088942,
      elevation: '159.9194641',
      capacity: 40351,
      year_constructed: 1922,
      grass: false,
      dome: false
    }
  },
  {
    id: 258,
    school: 'Virginia',
    mascot: 'Cavaliers',
    abbreviation: 'UVA',
    alt_name1: null,
    alt_name2: 'UVA',
    alt_name3: 'UVA',
    conference: 'ACC',
    division: 'Coastal',
    color: '#f84c1e',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/258.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/258.png'
    ],
    twitter: '@UVAFootball',
    location: {
      venue_id: 3923,
      name: 'Scott Stadium',
      city: 'Charlottesville',
      state: 'VA',
      zip: '22904',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 38.0311801,
      longitude: -78.5137897,
      elevation: '170.4677429',
      capacity: 61500,
      year_constructed: 1931,
      grass: true,
      dome: false
    }
  },
  {
    id: 259,
    school: 'Virginia Tech',
    mascot: 'Hokies',
    abbreviation: 'VT',
    alt_name1: null,
    alt_name2: 'VT',
    alt_name3: 'VT',
    conference: 'ACC',
    division: 'Coastal',
    color: '#74232D',
    alt_color: '#c2c1ba',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/259.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/259.png'
    ],
    twitter: '@VT_Football',
    location: {
      venue_id: 3799,
      name: 'Lane Stadium',
      city: 'Blacksburg',
      state: 'VA',
      zip: '24061',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 37.2199873,
      longitude: -80.4180643,
      elevation: '632.2631226',
      capacity: 65632,
      year_constructed: 1965,
      grass: true,
      dome: false
    }
  },
  {
    id: 154,
    school: 'Wake Forest',
    mascot: 'Demon Deacons',
    abbreviation: 'WAKE',
    alt_name1: null,
    alt_name2: 'WAKE',
    alt_name3: 'Wake Forest',
    conference: 'ACC',
    division: 'Atlantic',
    color: '#9E7E38',
    alt_color: '#000000',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/154.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/154.png'
    ],
    twitter: '@WakeFB',
    location: {
      venue_id: 3630,
      name: 'BB&T Field',
      city: 'Winston-Salem',
      state: 'NC',
      zip: '27105',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 36.1305507,
      longitude: -80.2545851,
      elevation: '283.548584',
      capacity: 31500,
      year_constructed: 1968,
      grass: false,
      dome: false
    }
  },
  {
    id: 264,
    school: 'Washington',
    mascot: 'Huskies',
    abbreviation: 'WASH',
    alt_name1: null,
    alt_name2: 'WASH',
    alt_name3: 'Washington',
    conference: 'Pac-12',
    division: null,
    color: '#2B2F64',
    alt_color: '#e8e3d3',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/264.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/264.png'
    ],
    twitter: '@UW_Football',
    location: {
      venue_id: 3765,
      name: 'Husky Stadium',
      city: 'Seattle',
      state: 'WA',
      zip: '98195',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 47.6503235,
      longitude: -122.3015746,
      elevation: '11.65622807',
      capacity: 70138,
      year_constructed: 1920,
      grass: false,
      dome: false
    }
  },
  {
    id: 265,
    school: 'Washington State',
    mascot: 'Cougars',
    abbreviation: 'WSU',
    alt_name1: null,
    alt_name2: 'WSU',
    alt_name3: 'Washington St',
    conference: 'Pac-12',
    division: null,
    color: '#94022a',
    alt_color: '#6a747c',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/265.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/265.png'
    ],
    twitter: '@WSUCougarFB',
    location: {
      venue_id: 3820,
      name: 'Martin Stadium',
      city: 'Pullman',
      state: 'WA',
      zip: '99164',
      country_code: 'US',
      timezone: 'America/Los_Angeles',
      latitude: 46.731831,
      longitude: -117.1604991,
      elevation: '773.9526367',
      capacity: 32952,
      year_constructed: 1972,
      grass: false,
      dome: false
    }
  },
  {
    id: 98,
    school: 'Western Kentucky',
    mascot: 'Hilltoppers',
    abbreviation: 'WKU',
    alt_name1: null,
    alt_name2: 'WKU',
    alt_name3: 'W Kentucky',
    conference: 'Conference USA',
    division: null,
    color: '#F32026',
    alt_color: '#b3b5b8',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/98.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/98.png'
    ],
    twitter: '@WKUFootball',
    location: {
      venue_id: 3796,
      name: 'Houchens Industries-L. T. Smith Stadium',
      city: 'Bowling Green',
      state: 'KY',
      zip: '42101',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 36.9847901,
      longitude: -86.4594041,
      elevation: '154.4346619',
      capacity: 22113,
      year_constructed: 1968,
      grass: false,
      dome: false
    }
  },
  {
    id: 2711,
    school: 'Western Michigan',
    mascot: 'Broncos',
    abbreviation: 'WMU',
    alt_name1: null,
    alt_name2: 'WMU',
    alt_name3: 'W Michigan',
    conference: 'Mid-American',
    division: 'West',
    color: '#532e1f',
    alt_color: '#8b7f79',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2711.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2711.png'
    ],
    twitter: '@WMU_Football',
    location: {
      venue_id: 3980,
      name: 'Waldo Stadium',
      city: 'Kalamazoo',
      state: 'MI',
      zip: '49008',
      country_code: 'US',
      timezone: 'America/Detroit',
      latitude: 42.2860064,
      longitude: -85.6007573,
      elevation: '243.8211212',
      capacity: 30200,
      year_constructed: 1939,
      grass: false,
      dome: false
    }
  },
  {
    id: 277,
    school: 'West Virginia',
    mascot: 'Mountaineers',
    abbreviation: 'WVU',
    alt_name1: null,
    alt_name2: 'WVU',
    alt_name3: 'West Virginia',
    conference: 'Big 12',
    division: null,
    color: '#FFC600',
    alt_color: '#eaaa00',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/277.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/277.png'
    ],
    twitter: '@WVUFootball',
    location: {
      venue_id: 3842,
      name: 'Mountaineer Field at Milan Puskar Stadium',
      city: 'Morgantown',
      state: 'WV',
      zip: '26507',
      country_code: 'US',
      timezone: 'America/New_York',
      latitude: 39.6502744,
      longitude: -79.9551873,
      elevation: '327.409668',
      capacity: 60000,
      year_constructed: 1980,
      grass: false,
      dome: false
    }
  },
  {
    id: 275,
    school: 'Wisconsin',
    mascot: 'Badgers',
    abbreviation: 'WIS',
    alt_name1: null,
    alt_name2: 'WIS',
    alt_name3: 'Wisconsin',
    conference: 'Big Ten',
    division: 'West',
    color: '#A00001',
    alt_color: '#f7f7f7',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/275.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/275.png'
    ],
    twitter: '@BadgerFootball',
    location: {
      venue_id: 347,
      name: 'Camp Randall Stadium',
      city: 'Madison',
      state: 'WI',
      zip: '53711',
      country_code: 'US',
      timezone: 'America/Chicago',
      latitude: 43.06994,
      longitude: -89.4126943,
      elevation: '263.604126',
      capacity: 80321,
      year_constructed: 1917,
      grass: false,
      dome: false
    }
  },
  {
    id: 2751,
    school: 'Wyoming',
    mascot: 'Cowboys',
    abbreviation: 'WYO',
    alt_name1: null,
    alt_name2: 'WYO',
    alt_name3: 'Wyoming',
    conference: 'Mountain West',
    division: 'Mountain',
    color: '#533B22',
    alt_color: '#ffffff',
    logos: [
      'https://a.espncdn.com/i/teamlogos/ncaa/500/2751.png',
      'https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2751.png'
    ],
    twitter: '@Wyo_Football',
    location: {
      venue_id: 3984,
      name: 'War Memorial Stadium',
      city: 'Laramie',
      state: 'WY',
      zip: '82071',
      country_code: 'US',
      timezone: 'America/Denver',
      latitude: 41.31161,
      longitude: -105.5681384,
      elevation: '2200.153564',
      capacity: 29181,
      year_constructed: 1950,
      grass: false,
      dome: false
    }
  }
]

export default fbsTeams
