import fbsTeams from './assets/fbs-teams'

const assembleTeams = () => {
  // Define the power 5 and group of 5 conferences
  const power5Conferences = ['ACC', 'Big 12', 'Big Ten', 'Pac-12', 'SEC']
  const group5Conferences = ['Conference USA', 'Mid-American', 'Mountain West', 'Sun Belt', 'American Athletic']

  // Filter the fbsTeams array to get power 5, group of 5, and independent schools
  const power5Schools = fbsTeams.filter(team => power5Conferences.includes(team.conference))
  const group5Schools = fbsTeams.filter(team => group5Conferences.includes(team.conference))
  const independentSchools = fbsTeams.filter(team => team.conference === 'FBS Independents')

  // Define the teams object and usedSchools array
  let teams = {}
  let usedSchools = []

  // Fill the teams object with seed numbers and random team until all 12 seeds are filled
  for (let i = 1; i <= 12; i++) {
    let randomSchool

    // Get a random power 5 school for seeds 1 to 8
    if (i <= 8) {
      randomSchool = power5Schools[Math.floor(Math.random() * power5Schools.length)]
    } else {
      // Get a random group of 5 or independent school for seeds 9 to 12
      const randomGroup5School = group5Schools[Math.floor(Math.random() * group5Schools.length)]
      const randomIndependentSchool = independentSchools[Math.floor(Math.random() * independentSchools.length)]
      randomSchool = Math.random() < 0.5 ? randomGroup5School : randomIndependentSchool
    }

    // Check if the school has been used
    if (!usedSchools.includes(randomSchool.school)) {
      usedSchools.push(randomSchool.school)
      teams[i] = randomSchool.school
    } else {
      // If the school has been used, repeat the loop
      i--
    }
  }

  // Randomize the seeds
  const seeds = Object.keys(teams)
  const randomSeeds = seeds.sort(() => Math.random() - 0.5)

  // Create a new teams object with randomized seeds
  let newTeams = {}
  for (let i = 0; i < randomSeeds.length; i++) {
    newTeams[randomSeeds[i]] = teams[seeds[i]]
  }

  // Update the teams object with the new teams object
  teams = newTeams

  return teams
}

export default assembleTeams