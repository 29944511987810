<template>
  <div class="history">
    <div class="year-nav">
      <router-link
        v-for="year in years"
        :to="{ name: 'year', params: { year } }"
      >{{ year }}</router-link>
    </div>
    <bracket :playoff="playoff" />
  </div>
</template>

<script>
  import cfpHistory from '@/assets/cfp-history'
  import Bracket from '@/components/Bracket'
  import TeamBadge from '@/components/TeamBadge'

  export default {
    name: 'History',
    props: {
      year: {
        type: Number,
        default: 2014
      }
    },
    data() {
      return {
        cfpHistory,
        // year: 2014,
        playoff: null
      }
    },
    components: {
      Bracket,
      TeamBadge
    },
    computed: {
      years() {
        return Object.keys(cfpHistory)
      }
    },
    methods: {
      // selectYear(year) {
      //   this.year = year
      // },
      // method to cycle through years
      // cycleYear(foward) or cycleYear(backward)
      // the above code needs to assume the year starts at 2014, the first year in the array
      // if the year is 2014 and the user clicks the back button, the year should be 2024
      // if the year is 2024 and the user clicks the forward button, the year should be 2014
    },
    watch: {
      year: {
        handler(newYear, oldYear) {
          // console.log('year watcher: this.year', this.year)
          // console.log('year watcher: this.cfpHistory[this.year]', this.cfpHistory[newYear])
          this.playoff = this.cfpHistory[newYear]
        }
        // console.log('year watcher: this.cfpHistory[this.year]', this.cfpHistory[this.year])
        // this.playoff = this.cfpHistory[this.year]
      }
    },
    created() {
      // console.log('created: this.cfpHistory', this.cfpHistory)
      // console.log('created: this.year', this.year)
      this.playoff = this.cfpHistory[this.year]
      // console.log('created: this.playoff', this.playoff)
    },
    mounted() {
      // console.log('mounted: this.year', this.year)
      // this.playoff = this.cfpHistory[this.year]
      // console.log('mounted: this.playoff', this.playoff)
    }
  }
</script>

<style lang="scss" scoped>
  .history {
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // overflow: hidden;
    // display: flex;
    // flex-flow: column nowrap;
    // justify-content: center;
    // align-items: center;
    // width: 100vw;
    height: 100%;
    // background-color: blue;
    // padding: 10%;
  }

  .controls {
    // display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 220px;
    height: 40px;
    // width: 100%;
    z-index: 1;
    // display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(59, 59, 59);
  }

  .year-nav {
    position: absolute;
    top: 60px;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;

    a {
      display: inline-block;
      padding: 10px;
      color: white;
      text-decoration: none;

      &:hover {
        background-color: rgb(59, 59, 59);
      }

      &.router-link-exact-active {
        background-color: rgb(59, 59, 59);
      }
  }
}</style>
