import { createStore } from 'vuex'

export default createStore({
  state: {
    theme: 'dark'
  },
  getters: {
  },
  mutations: {
    setTheme: (state, data) => {
      state.theme = data
    },
  },
  actions: {
    toggleTheme({ state, commit }) {
      let newTheme = state.theme == 'dark' ? 'light' : 'dark'
      document.documentElement.setAttribute('data-color-theme', newTheme)
      commit('setTheme', newTheme)
    }
  },
  modules: {
  }
})
