<template>
  <div
    :class="[
      'team-badge',
      `team-seed-${team.seed}`,
      classes
    ]"
    @click="selectTeam(team)"
  >
    <div class="team-badge__seed">
      {{ team.seed }}
    </div>
    <!-- if selectablee and team.seed isn't present -->
    <!-- <template v-if="selectablee && !team.seed">
                          <select @change="updateTeam(index, game, $event)">
                            <option
                              v-for="team in teamList"
                              :key="team.id"
                              :value="team.name"
                            >
                              {{ team.name }}
                            </option>
                          </select>
                        </template> -->
    <!-- <template> -->
    <div class="team-badge__name">{{ team.name || 'TBD' }}
    </div>
    <div
      v-if="!teamTBD"
      class="team-badge__logo"
    >
      <img
        :src="getTeamLogo(team.name)"
        :alt="`${team.name} logo`"
      >
    </div>
    <!-- </template> -->
  </div>
</template>

<script>
  import fbsTeams from '../assets/fbs-teams'

  export default {
    name: 'TeamBadge',
    props: {
      disableSelect: {
        type: Boolean,
        default: false
      },
      team: {
        type: Object,
        required: true
      },
      // team object:
      // {
      //   name: 'Notre Dame' || 'TBD', 
      //   seed: 1 || '',
      // }
      outcome: {
        type: String,
        default: ''
      },
      selectable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        fbsTeams
      }
    },
    // inject: ['selectablee'],
    inject: {
      selectablee: {
        default: false,
        optional: true
      }
    },
    computed: {
      classes() {
        return {
          'team-badge--winner': this.outcome === 'winner',
          'team-badge--loser': this.outcome === 'loser',
          'team-badge--tbd': this.outcome === 'TBD',
          'team-badge--selectable': this.selectablee,
          'team-badge--disabled': this.disableSelect
        }
      },
      teamList() {
        // map fbsTeams school and id to a new array
        const list = this.fbsTeams.map(team => {
          return {
            name: team.school,
            id: team.id
          }
        })
        // add a blank option to the beginning of the array
        list.unshift({
          name: 'Choose team…',
          id: ''
        })
        return list
      },
      teamTBD() {
        return this.team.name === 'TBD'
      }
    },
    methods: {
      // returns the team logo - might be able to use findTeam() method instead
      getTeamLogo(teamName) {
        // return if teamName is 'TBD'
        if (teamName === 'TBD') return
        // find the team in fbsTeams array
        const team = fbsTeams.find(team => team.school === teamName)
        // return the team logo
        return team.logos[0]
      },
      selectTeam(selectedTeam) {
        // get the data-game value from the parent element
        if (!this.selectablee) return
        const selectedGame = this.$el.parentElement.dataset.game
        console.log('TeamBadge: selectTeam', selectedGame, selectedTeam)
        // if not selectablee, do nothing
        this.$emit('select-team', selectedGame, selectedTeam)
      },
      updateTeam(index, game, event) {
        this.$emit('update-team', index, game, event.target.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .team-badge {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: var(--drop-shadow);
    color: var(--text--black);
    user-select: none;
    container-type: size;
    container-name: team-badge;

    &--selectable {
      cursor: pointer;
    }

    &--tbd {}

    &--winner {
      background: linear-gradient(90deg, rgba(255, 237, 136, 1) 0%, rgba(255, 255, 255, 1) 90%);
    }

    &--loser {
      background-color: rgb(207, 207, 207);

      .team-badge__seed {
        // background: none;
      }

      .team-badge__name {
        // color: gray;
      }

      img {
        // filter: grayscale(1);
        // opacity: 0.75;
      }
    }

    &__seed {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 25px;
      height: 100%;
      background-color: rgb(235, 235, 235);
      font-family: 'factoria-demi';
      font-size: 1.4rem;

      .team-badge--winner & {
        background-color: gold;
      }
    }

    @container team-badge (min-width: 150px) {
      &__seed {
        flex: 0 0 50px;
        font-size: 1.8rem;
      }
    }

    &__name {
      flex: 1 1 auto;
      font-size: 14px;
      line-height: 1.1;
      // padding-top: 2px;
      text-align: left;
    }

    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 50px;
      height: 50px;

      img {
        width: auto;
        height: 90%;
        object-fit: cover;
      }
    }
  }
</style>