import { createRouter, createWebHistory } from 'vue-router'
import History from '../views/History.vue'
import Randomizer from '../views/Randomizer.vue'
import CreateBracket from '../views/CreateBracket.vue'
import Chooser from '../views/Chooser.vue'
import ComingSoon from '../views/ComingSoon.vue'

const routes = [
  {
    path: '/',
    redirect: '/coming-soon',
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoon
  },
  {
    path: '/history',
    redirect: '/history/2014'
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    children: [
      {
        path: '/history/:year',
        name: 'year',
        component: History,
      }
    ],
    props: route => ({ year: Number(route.params.year) })
  },
  {
    path: '/randomizer',
    name: 'randomizer',
    component: Randomizer
  },
  {
    path: '/create',
    name: 'create',
    redirect: '/create/choose'
    // redirect to either /create/select or /create/bracket
    // redirect: to => {
    //   if (to.query.bracket) {
    //     return { name: 'bracket', query: to.query }
    //   } else {
    //     return { name: 'select', query: to.query }
    //   }
    // }
  },
  {
    path: '/create/choose',
    name: 'choose',
    component: Chooser
  },
  {
    path: '/create/bracket',
    name: 'bracket',
    component: CreateBracket,
    // props: route => ({ teams: route.params.teams })
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
