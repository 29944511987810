<template>
  <div class="creator">
    <div class="controls">
      <ep-button
        label="Edit Teams"
        kind="ghost"
        @click=""
      />
      <ep-button
        label="Share Bracket"
        kind="ghost"
        @click=""
      />
    </div>
    <bracket
      ref="bracket"
      :playoff="playoff"
      @update-team="updatePlayoff"
      @select-team="selectTeam"
    />
  </div>
</template>

<script>
  import Bracket from '@/components/Bracket'

  export default {
    name: 'Creator',
    provide: {
      disableSelect: true,
      selectablee: true
    },
    // props: {
    //   teams: {
    //     type: Object,
    //     default: () => ({})
    //   }
    // },
    data() {
      return {
        usedTeams: [],
        // fbsTeams,
        // order of selection in the form of game/seed
        selectionOrder: [
          // i need an initial view where the user selects their top 12 teams
          // then the bracket will be pre-populated with all 12 seeds
          // and they can start selecting matchups starting with game 5
        ],
        selectorEnabled: {
          // for each game, the selector will be either disabled or not for each team
          // first round - all teams are selectable
          1: [true, true],
          2: [true, true],
          3: [true, true],
          4: [true, true],
          // second round - seeds 1-4 are selectable
          // once the first round is complete, the second teams for games 5-8 will be selectable
          // for example, game 5 would change to this after both game 1 teams have been selected:
          // 5: [true, true],
          5: [true, false],
          6: [true, false],
          7: [true, false],
          8: [true, false],
          9: [false, false],
          10: [false, false],
          11: [false, false],
        },
        playoff: {
          // "teams": {
          //   "1": "Texas A&M",
          //   "2": "Oregon",
          //   "3": "Auburn",
          //   "4": "Colorado",
          //   "5": "South Carolina",
          //   "6": "LSU",
          //   "7": "Miami",
          //   "8": "Purdue",
          //   "9": "New Mexico State",
          //   "10": "BYU",
          //   "11": "Tulsa",
          //   "12": null
          // },
          "teams": null,
          "games": {
            // round 1
            "1": [8, 9, null, null],
            "2": [7, 10, null, null],
            "3": [5, 12, null, null],
            "4": [6, 11, null, null],
            // round 2
            "5": [1, null, null, null],
            "6": [2, null, null, null],
            "7": [4, null, null, null],
            "8": [3, null, null, null],
            // round 3
            "9": [null, null, null, null],
            "10": [null, null, null, null],
            // title game
            "11": [null, null, null, null]
          }
        }
      }
    },
    components: {
      Bracket
    },
    methods: {
      selectTeam(selectedGame, selectedTeam) {
        console.log('Creator: selectTeam: selectedGame, selectedTeam', selectedGame, selectedTeam)
        // if game 1 teams are selected
        if (selectedGame == 1) {
          // no matter what, declare it the winner of game 1
          this.playoff.games[1][2] = selectedTeam.seed
          // and add the team to game 5, position 2
          this.playoff.games[5][1] = selectedTeam.seed
          // if the winner of game 5 is different than selectedTeam.seed, and not seed 1, remove it too
          if (this.playoff.games[5][2] != selectedTeam.seed && this.playoff.games[5][2] != 1) {
            this.playoff.games[5][2] = null
          }
          // if the game 9 participant is different than selectedTeam.seed, and not seed 1, remove it too
          if (this.playoff.games[9][0] != selectedTeam.seed && this.playoff.games[9][0] != 1) {
            this.playoff.games[9][0] = null
            // remove the winner of game 9 too
            this.playoff.games[9][2] = null
          }
          // if the game 11 participant is different than selectedTeam.seed, and not seed 1, remove it too
          if (this.playoff.games[11][0] != selectedTeam.seed && this.playoff.games[11][0] != 1) {
            this.playoff.games[11][0] = null
            // remove the winner of game 11 too
            this.playoff.games[11][2] = null
          }
        }
        if (selectedGame == 2) {
          // add game 2 winner to game 2 third position
          this.playoff.games[2][2] = selectedTeam.seed
          // add game 2 winner to game 6
          this.playoff.games[6][1] = selectedTeam.seed
        }
        if (selectedGame == 3) {
          // add game 3 winner to game 3 third position
          this.playoff.games[3][2] = selectedTeam.seed
          // add game 3 winner to game 7
          this.playoff.games[7][1] = selectedTeam.seed
        }
        if (selectedGame == 4) {
          // add game 4 winner to game 4 third position
          this.playoff.games[4][2] = selectedTeam.seed
          // add game 4 winner to game 8
          this.playoff.games[8][1] = selectedTeam.seed
        }
        if (selectedGame == 5) {
          // add game 5 winner to game 5 third position
          this.playoff.games[5][2] = selectedTeam.seed
          // add game 5 winner to game 9
          this.playoff.games[9][0] = selectedTeam.seed
        }
        if (selectedGame == 6) {
          // add game 6 winner to game 6 third position
          this.playoff.games[6][2] = selectedTeam.seed
          // add game 6 winner to game 10
          this.playoff.games[10][0] = selectedTeam.seed
        }
        if (selectedGame == 7) {
          // add game 7 winner to game 7 third position
          this.playoff.games[7][2] = selectedTeam.seed
          // add game 7 winner to game 9
          this.playoff.games[9][1] = selectedTeam.seed
        }
        if (selectedGame == 8) {
          // add game 8 winner to game 8 third position
          this.playoff.games[8][2] = selectedTeam.seed
          // add game 8 winner to game 10
          this.playoff.games[10][1] = selectedTeam.seed
        }
        if (selectedGame == 9) {
          // add game 9 winner to game 9 third position
          this.playoff.games[9][2] = selectedTeam.seed
          // add game 9 winner to game 11
          this.playoff.games[11][0] = selectedTeam.seed
        }
        if (selectedGame == 10) {
          // add game 10 winner to game 10 third position
          this.playoff.games[10][2] = selectedTeam.seed
          // add game 10 winner to game 11
          this.playoff.games[11][1] = selectedTeam.seed
        }
        if (selectedGame == 11) {
          // add game 11 winner to game 11 third position
          this.playoff.games[11][2] = selectedTeam.seed
        }

      },
      updatePlayoff({ team, game, round }) {
        this.playoff.teams[team] = game
        this.playoff.games[round][game] = team
      }
    },
    watch: {
      playoff: {
        handler(newVal, oldVal) {
          console.log('Create Bracket: watch: playoff: this.playoff', this.playoff)
          // this.playoff.teams = this.teams
        },
        deep: true
      }
    },
    created() {
      // console.log('Creator: created', this.availableTeams)
      // accept the query string object as a prop
      // console.log('Creator: created: this.query', this.query)
      // this.availableTeams = this.query.availableTeams
      // console.log('Creator: created: this.availableTeams', this.availableTeams)
      const query = this.$route.query
      // do the opposite of JSON.stringify on the query string object
      const parsedTeams = JSON.parse(query.teams)
      console.log('Create Bracket: created: parsedTeams', parsedTeams)
      this.playoff.teams = parsedTeams
      console.log('Create Bracket: created: this.playoff', this.playoff)
    }
  }
</script>

<style lang="scss" scoped>
  .creator {
    height: 100%;
    // padding-top: 101px;
  }

  .img-modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 2;
  }

  .controls {
    // display: none;
    position: absolute;
    top: 61px;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // width: 100%;
    z-index: 1;
    // display: flex;
    justify-content: center;
    align-items: center;
    background: #ff00a561;
    // background-color: rgb(31, 31, 31);
  }
</style>
