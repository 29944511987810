const cfpHistory = {
  2014: {
    teams: {
      1: 'Alabama',
      2: 'Oregon',
      3: 'Florida State',
      4: 'Ohio State'
    },
    // [seed, seed, winning seed, score]
    games: {
      1: [1, 4, 4, '42-35'],
      2: [2, 3, 2, '59-20'],
      3: [2, 4, 4, '42-20']
    }
  },
  2015: {
    teams: {
      1: 'Clemson',
      2: 'Alabama',
      3: 'Michigan State',
      4: 'Oklahoma'
    },
    games: {
      1: [1, 4, 1, '37-17'],
      2: [2, 3, 2, '38-0'],
      3: [1, 2, 2, '45-40']
    }
  },
  2016: {
    teams: {
      1: 'Alabama',
      2: 'Clemson',
      3: 'Ohio State',
      4: 'Washington'
    },
    games: {
      1: [1, 4, 1, '24-7'],
      2: [2, 3, 2, '31-0'],
      3: [1, 2, 2, '35-31']
    }
  },
  2017: {
    teams: {
      1: 'Clemson',
      2: 'Oklahoma',
      3: 'Georgia',
      4: 'Alabama',
    },
    games: {
      1: [1, 4, 4, '24-6'],
      2: [2, 3, 3, '54-48 2OT'],
      3: [3, 4, 4, '26-23 OT']
    }
  },
  2018: {
    teams: {
      1: 'Alabama',
      2: 'Clemson',
      3: 'Notre Dame',
      4: 'Oklahoma'
    },
    games: {
      1: [1, 4, 1, '45-34'],
      2: [2, 3, 2, '30-3'],
      3: [1, 2, 2, '44-16']
    }
  },
  2019: {
    teams: {
      1: 'LSU',
      2: 'Ohio State',
      3: 'Clemson',
      4: 'Oklahoma'
    },
    games: {
      1: [1, 4, 1, '63-28'],
      2: [2, 3, 3, '29-23'],
      3: [1, 3, 1, '42-25']
    }
  },
  2020: {
    teams: {
      1: 'Alabama',
      2: 'Clemson',
      3: 'Ohio State',
      4: 'Notre Dame'
    },
    games: {
      1: [1, 4, 1, '31-14'],
      2: [2, 3, 3, '49-28'],
      3: [1, 3, 1, '52-24']
    }
  },
  2021: {
    teams: {
      1: 'Alabama',
      2: 'Michigan',
      3: 'Georgia',
      4: 'Cincinnati'
    },
    games: {
      1: [1, 4, 1, '27-6'],
      2: [2, 3, 3, '34-11'],
      3: [1, 3, 3, '33-18']
    }
  },
  2022: {
    teams: {
      1: 'Georgia',
      2: 'Michigan',
      3: 'TCU',
      4: 'Ohio State'
    },
    games: {
      1: [1, 4, 1, '42-41'],
      2: [2, 3, 3, '51-45'],
      3: [1, 3, 1, '65-7']
    }
  },
  2023: {
    teams: {
      1: null,
      2: null,
      3: null,
      4: null
    },
    games: {
      1: [null, null, null, null],
      2: [null, null, null, null],
      3: [null, null, null, null]
    }
  },
  2024: {
    teams: {
      1: 'Alabama',
      2: 'Clemson',
      3: 'Ohio State',
      4: 'Notre Dame',
      5: 'Georgia',
      6: 'Michigan',
      7: 'TCU',
      8: 'Oklahoma',
      9: 'LSU',
      10: 'Cincinnati',
      11: 'Michigan State',
      12: 'Florida State',
    },
    games: {
      1: [null, null, null, null],
      2: [null, null, null, null],
      3: [null, null, null, null],
      4: [null, null, null, null],
      5: [null, null, null, null],
      6: [null, null, null, null],
      7: [null, null, null, null],
      8: [null, null, null, null],
      9: [null, null, null, null],
      10: [null, null, null, null],
      11: [null, null, null, null],
    }
  }
}

export default cfpHistory
