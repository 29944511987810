<template>
  <div class="chooser-container">
    <ep-container v-bind="containerProps">
      <template #header>
        <ep-header>
          <template #left>
            <h1>Your top 12 teams</h1>
          </template>
        </ep-header>
      </template>
      <div class="chooser">
        <div class="chooser__options">
          <ep-checkbox
            label="Current top 25 only"
            id="top25"
            v-model="top25"
          />
          {{ top25 }}
        </div>
        <div
          v-for="n in 12"
          :key="n"
          class="chooser__seed"
        >
          <div class="chooser__seed-label">
            {{ n }}
          </div>
          <div class="chooser__seed-select">
            <ep-select
              :id="n.toString()"
              :options="availableTeams"
              placeholder="Select a team"
              v-model="teams[n]"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <ep-footer itemGap="1rem">
          <template #right>
            <ep-button
              label="AutoFill"
              @click="autoFill"
            />
            <ep-button
              label="Start Bracket"
              kind="primary"
              :disabled="!selectionsComplete"
              @click="startBracket"
            />
          </template>
        </ep-footer>
      </template>
    </ep-container>
  </div>
</template>

<script>
  import fbsTeams from '../assets/fbs-teams'

  export default {
    name: 'Chooser',
    data() {
      return {
        containerProps: {
          width: '40rem',
          padding: '2rem 3rem 3rem',
          overflow: 'hidden',
        },
        fbsTeams,
        teams: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
          7: null,
          8: null,
          9: null,
          10: null,
          11: null,
          12: null,
        },
        top25: false,
      }
    },
    computed: {
      selectionsComplete() {
        // if no teams are null, return true
        return !Object.values(this.teams).includes(null)
      },
      availableTeams() {
        // map fbsTeams school and id to a new array
        const list = this.fbsTeams.map(team => {
          // if (this.usedTeams.includes(team.school)) {
          //   return
          // }
          return {
            label: team.school,
            value: team.school,
            disabled: this.isDisabled(team.school)
          }
        })
        return list
      },
      fakeTop25() {
        return this.fbsTeams.slice(0, 25)
      }
    },
    methods: {
      isDisabled(teamName) {
        // if the value is 'Choose team…', return false
        if (teamName === 'Choose team…') {
          return true
        }
        // if the teamName is in the teams object, return true
        return Object.values(this.teams).includes(teamName)
      },
      isSelected(teamName) {
        // show 'Choose team…' if no selection has been made
        if (teamName === 'Choose team…') {
          return true
        }
      },
      updateSelections(seed, event) {
        console.log('Creator: updateSelections', seed, event.target.value)
        // add team to usedTeams array
        // this.usedTeams.push(event.target.value)
      },
      startBracket() {
        console.log('Creator: startBracket')
        this.$router.push({
          name: 'bracket',
          // need to pass this.teams to the bracket view
          query: {
            teams: JSON.stringify(this.teams)
          }
        })
      },
      autoFill() {
        // prefill the this.teams object with 12 random teams from the fbsTeams array, no duplicates
        const teams = this.fbsTeams
        const usedTeams = []
        for (let i = 1; i <= 12; i++) {
          let randomTeam = teams[Math.floor(Math.random() * teams.length)]
          if (usedTeams.includes(randomTeam.school)) {
            randomTeam = teams[Math.floor(Math.random() * teams.length)]
          }
          usedTeams.push(randomTeam.school)
          this.teams[i] = randomTeam.school
        }
      }
    },
    watch: {
      teams: {
        handler() {
          console.log('Choose: watch: teams', this.teams)
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chooser-container {
    position: absolute;
    top: 100px;
    width: 100%;
    display: flex;
    justify-content: center;

    .chooser {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__seed {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: stretch;
        gap: 10px;

        &-label {
          flex: 0 0 20px;
          text-align: right;
        }

        &-select {
          flex: 1;
        }
      }
    }
  }
</style>