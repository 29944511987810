const addScores = (games) => {
  // add a random game score, two digits each, highest number first, to the fourth position of each game array as a string like '45-14'
  for (let i = 1; i <= 11; i++) {
    let winningScore = Math.floor(Math.random() * 61)
    let losingScore = Math.floor(Math.random() * 61)
    // if either score is between 1 or 2, add a random number between 1 and 10 to the score
    // need to address 4 and 5 as well
    while (winningScore >= 1 && winningScore < 3 || winningScore >= 4 && winningScore < 6) {
      winningScore = winningScore + Math.floor(Math.random() * 10) + 1
    }
    while (losingScore >= 1 && losingScore < 3 || losingScore >= 4 && losingScore < 6) {
      losingScore = losingScore + Math.floor(Math.random() * 10) + 1
    }
    // if scores are the same, add a random number between 1 and 10 to the winning score
    if (winningScore === losingScore) {
      winningScore = winningScore + Math.floor(Math.random() * 10) + 1
    }
    // reverse the scores if losing score is higher than winning score
    if (winningScore > losingScore) {
      games[i][3] = `${winningScore}-${losingScore}`
    } else {
      games[i][3] = `${losingScore}-${winningScore}`
    }
    // if the difference between the scores is 3 or 6, add "OT" to the score string randomly, but extremely rarely
    if (Math.abs(winningScore - losingScore) === 3 || Math.abs(winningScore - losingScore) === 6) {
      switch (true) {
        case Math.random() < 0.3:
          games[i][3] += ' OT'
          break
        case Math.random() < 0.2:
          games[i][3] += ' 2OT'
          break
        case Math.random() < 0.01:
          games[i][3] += ' 3OT'
          break
        default:
          break
      }
    }
  }
  return games
}

export default addScores

