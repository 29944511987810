<template>
  <div class="cfp-games">
    <nav>
      <ep-header>
        <template #left>
          <h1><span>(=) CFP</span> Games</h1>
        </template>
        <template #center>
          <ep-button
            v-for="item in navItems"
            :label="item.label"
            kind="ghost"
            size="large"
            :to="item.to"
          />
        </template>
        <template #right></template>
      </ep-header>
    </nav>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        navItems: [
          {
            label: 'CFP History',
            to: '/history'
          },
          {
            label: 'Create Bracket',
            to: '/create/choose'
          },
          {
            label: 'Randomizer',
            to: '/randomizer'
          }
        ]
      }
    },
    computed: {
      activeParent() {
        return this.$route.matched.some(route => route.path === '/history')
      }
    }
  }
</script>

<style lang="scss">
  @import '@/assets/scss/fonts.scss';

  #app {
    height: 100vh;
  }

  // body,
  // html {
  //   margin: 0;
  //   padding: 0;
  // }
  // * {
  //   box-sizing: border-box;
  // }
  body {
    background-color: var(--background-1);
    background-image: url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--text-color);
    font-family: 'HelveticaNow', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variation-settings: 'wght' 600, 'wdth' 80, 'opsz' 14 !important;
  }

  .cfp-games {
    height: 100%;
    display: flex;
    flex-direction: column;

    nav {
      // position: absolute;
      // top: 0;
      // left: 0;
      // bottom: 0;
      flex: 0 0 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      height: 61px;
      z-index: 1;
      background: var(--background-2);
      border-bottom: 1px solid var(--border-color);

      h1 {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
        font-family: 'factoria-demi';
        font-size: var(--font-size--body);
        letter-spacing: 0.1rem;

        span {
          font-size: var(--font-size--large);
          color: gold;
        }
      }
    }

    main {
      flex: 1 1 auto;
    }
  }
</style>
