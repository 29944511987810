<template>
  <div class="randomizer">
    <div class="controls">
      <ep-button
        label="Refresh"
        kind="ghost"
        @click="createRandomPlayoff"
      />
      <ep-button
        label="Share Bracket"
        kind="ghost"
        @click=""
      />
    </div>
    <bracket
      ref="bracket"
      :playoff="playoff"
    />
  </div>
</template>

<script>
  import assembleTeams from '@/assembleTeams'
  import chooseWinners from '@/chooseWinners'
  import addScores from '@/addScores'
  import Bracket from '@/components/Bracket'

  export default {
    name: 'Randomizer',
    data() {
      return {
        playoff: null
      }
    },
    components: {
      Bracket
    },
    computed: {
      years() {
        return Object.keys(cfpHistory)
      }
    },
    methods: {
      createRandomPlayoff() {
        // create fake playoff object
        const teams = assembleTeams()
        const games = chooseWinners()
        addScores(games)

        const playoff = {
          teams,
          games
        }

        // add playoff to query string
        const url = new URL(window.location)
        url.searchParams.set('playoff', JSON.stringify(playoff))
        window.history.pushState({}, '', url)

        this.playoff = playoff
        // console.clear()
        console.log('randomizer: this.playoff', this.playoff)
      }
    },
    created() {
      // if query string exists, use it
      const url = new URL(window.location)
      const playoff = url.searchParams.get('playoff')
      if (playoff) {
        this.playoff = JSON.parse(playoff)
        return
      } else {
        this.createRandomPlayoff()
      }
      // console.log('created: this.playoff', this.playoff)
    },
    watch: {
      '$route.query.playoff': function (playoff) {
        if (playoff) {
          this.playoff = JSON.parse(playoff)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .randomizer {
    height: 100%;
    // padding-top: 101px;
  }

  .img-modal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 2;
  }

  .controls {
    // display: none;
    position: absolute;
    top: 61px;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // width: 100%;
    z-index: 1;
    // display: flex;
    justify-content: center;
    align-items: center;
    background: #ff00a561;
    // background-color: rgb(31, 31, 31);
  }
</style>
