// choose winners - fill in the null values in games object with the seed numbers of the winners
// we have to decide the winners of games 1-4 (round 1) before we can move on to games 5-8 (round 2)
// round 1
// game 1 winner (seed 8 or 9) will fill the null spot in game 5
// game 2 winner (seed 7 or 10) will fill the null spot in game 6
// game 3 winner (seed 5 or 12) will fill the null spot in game 7
// game 4 winner (seed 6 or 11) will fill the null spot in game 8
// round 2
// game 5 winner (seed 1 or winner of game 1) will fill the first null spot in game 9
// game 6 winner (seed 2 or winner of game 2) will fill the first null spot in game 10
// game 7 winner (seed 4 or winner of game 3) will fill the second null spot in game 9
// game 8 winner (seed 3 or winner of game 4) will fill the second null spot in game 10
// round 3
// game 9 winner (winner of game 5 or winner of game 7) will fill the null spot in game 11
// game 10 winner (winner of game 6 or winner of game 8) will fill the second null spot in game 11
// title game
// game 11 winner (winner of game 9 or winner of game 10) will be the champion

const chooseWinners = () => {
  const games = {
    // round 1
    1: [8, 9],
    2: [7, 10],
    3: [5, 12],
    4: [6, 11],
    // round 2
    5: [1, null],
    6: [2, null],
    7: [4, null],
    8: [3, null],
    // round 3
    9: [null, null],
    10: [null, null],
    // title game
    11: [null, null]
  }

  const rounds = [
    [1, 2, 3, 4], // round 1
    [5, 6, 7, 8], // round 2
    [9, 10] // round 3
  ]

  // ROUND 1 WINNERS
  // loop through the games in round 1 and assign them to round 2
  for (let i = 0; i < rounds[0].length; i++) {
    // get the game number
    const game = rounds[0][i]
    // get the seed numbers for the game
    const [seedA, seedB] = games[game]
    // get the winner of the game
    const winner = Math.random() > 0.5 ? seedA : seedB
    // set the winning seed to the game array in the third position
    games[game][2] = winner
    // set the winner of the game to round 2
    games[game + 4][1] = winner
  }

  // ROUND 2 WINNERS
  // loop through the games in round 2 and assign them to round 3
  for (let i = 0; i < rounds[1].length; i++) {
    // get the game number
    const game = rounds[1][i]
    // get the seed numbers for the game
    const [seedA, seedB] = games[game]
    // get the winner of the game
    const winner = Math.random() > 0.5 ? seedA : seedB
    // set the winning seed to the game array in the third position
    games[game][2] = winner
    // set the winner of the game to round 3
    // game 9 will be the winners of games 5 and 7
    // game 10 will be the winners of games 6 and 8
    if (game === 5) {
      games[9][0] = winner
    }
    if (game === 7) {
      games[9][1] = winner
    }
    if (game === 6) {
      games[10][0] = winner
    }
    if (game === 8) {
      games[10][1] = winner
    }
  }

  // ROUND 3 WINNERS
  // loop through the games in round 3 and assign them to the title game
  for (let i = 0; i < rounds[2].length; i++) {
    // get the game number
    const game = rounds[2][i]
    // get the seed numbers for the game
    const [seedA, seedB] = games[game]
    // get the winner of the game
    const winner = Math.random() > 0.5 ? seedA : seedB
    // set the winning seed to the game array in the third position
    games[game][2] = winner
    // set the winner of the game to the title game
    // game 11 will be the winner of games 9 and 10
    if (game === 9) {
      games[11][0] = winner
    }
    if (game === 10) {
      games[11][1] = winner
    }
  }

  // GET THE WINNER
  // get the winner of the title game
  const [seedA, seedB] = games[11]
  const winner = Math.random() > 0.5 ? seedA : seedB
  // set the winning seed to the game array in the third position
  games[11][2] = winner

  // return the games object
  return games
}

export default chooseWinners