<template>
  <div class="coming-soon">
    <h1>Coming Soon</h1>
  </div>
</template>

<style lang="scss" scoped>
  .coming-soon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--background-1);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
</style>