<template>
  <div class="bracket-container">
    <div :class="[
      'bracket',
      { 'four-team': numGames == 3 },
      { 'twelve-team': numGames == 11 }
    ]">
      <div
        v-for="(game, index) in playoff.games"
        :class="['game', `game-${index}`]"
        :id="`game-${index}`"
      >
        <!-- if game 11 add trophy img -->
        <img
          v-if="numGames == 11 && index == 11 || numGames == 3 && index == 3"
          class="game__trophy"
          src="../assets/cfp-trophy.png"
          alt="trophy"
        />
        <div
          class="game-container"
          :id="`game-${index}-container`"
          :data-game="index"
        >
          <template
            v-for="(team, index) in getMatchup(index)"
            :key="index"
          >
            <team-badge
              :team="team"
              :outcome="determineOutcome(game, team)"
              :id="badgeID(game, index, team)"
              @select-team="selectTeam"
            />
            <div
              v-if="index == 0"
              class="game__score"
            >{{ game[3] }}</div>
          </template>
        </div>
      </div>
    </div>
    <svg
      width="100%"
      height="100%"
    ></svg>
  </div>
</template>

<script>
  import * as d3 from 'd3'
  import TeamBadge from './TeamBadge'

  export default {
    name: 'Bracket',
    props: {
      playoff: {
        type: Object,
        required: true
      }
      // example:
      // 2014: {
      //   teams: {
      //     1: 'Alabama',
      //     2: 'Oregon',
      //     3: 'Florida State',
      //     4: 'Ohio State'
      //   },
      //   // [seed, seed, winning seed, score]
      //   games: {
      //     1: [1, 4, 4, '42-35'],
      //     2: [2, 3, 2, '59-20'],
      //     3: [2, 4, 4, '42-20']
      //   }
      // }
    },
    components: {
      TeamBadge
    },
    data() {
      return {
      }
    },
    methods: {
      badgeID(game, index, team) {
        // if the team is the winner, add a game-1-winner id
        if (this.determineOutcome(game, team) === 'winner') {
          return `game-${index}-winner`
        }
      },
      determineOutcome(game, team) {
        // if null, game hasn't been played yet
        if (!game[2]) return 'TBD'
        return game[2] == team.seed ? 'winner' : 'loser'
      },
      drawConnections() {
        d3.selectAll('line').remove()
        const strokeColor = 'var(--gray-350)'
        const svg = d3.select('svg')
        let games = []
        let connections = []

        if (this.numGames == 3) {
          games = [
            { id: 'game-1', node: d3.select('#game-1') },
            { id: 'game-2', node: d3.select('#game-2') },
            { id: 'game-3', node: d3.select('#game-3') }
          ]
          connections = [
            { start: 'game-1', end: 'game-3' },
            { start: 'game-2', end: 'game-3' }
          ]
        } else if (this.numGames == 11) {
          games = [
            { id: 'game-1', node: d3.select('#game-1-container') },
            { id: 'game-2', node: d3.select('#game-2-container') },
            { id: 'game-3', node: d3.select('#game-3-container') },
            { id: 'game-4', node: d3.select('#game-4-container') },
            { id: 'game-5', node: d3.select('#game-5-container') },
            { id: 'game-6', node: d3.select('#game-6-container') },
            { id: 'game-7', node: d3.select('#game-7-container') },
            { id: 'game-8', node: d3.select('#game-8-container') },
            { id: 'game-9', node: d3.select('#game-9-container') },
            { id: 'game-10', node: d3.select('#game-10-container') },
            { id: 'game-11', node: d3.select('#game-11-container') }
          ]
          connections = [
            { start: 'game-1', end: 'game-5' },
            { start: 'game-2', end: 'game-6' },
            { start: 'game-3', end: 'game-7' },
            { start: 'game-4', end: 'game-8' },
            { start: 'game-5', end: 'game-9' },
            { start: 'game-6', end: 'game-10' },
            { start: 'game-7', end: 'game-9' },
            { start: 'game-8', end: 'game-10' },
            { start: 'game-9', end: 'game-11' },
            { start: 'game-10', end: 'game-11' }
          ]
        }

        const drawLine = (x1, y1, x2, y2) => {
          svg
            .append('line')
            .attr('x1', x1)
            .attr('y1', y1)
            .attr('x2', x2)
            .attr('y2', y2)
            .attr('stroke', strokeColor)
            .attr('stroke-width', 3)
            .attr('stroke-linecap', 'round')
        }

        games.forEach((game, index) => {
          const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = game.node.node()
          // console.log(offsetLeft)
          game.left = offsetLeft
          game.right = offsetLeft + offsetWidth
          game.top = offsetTop
          game.bottom = offsetTop + offsetHeight
          game.width = offsetWidth
          game.height = offsetHeight
        })

        connections.forEach(connection => {
          const start = games.find(game => game.id === connection.start)
          const end = games.find(game => game.id === connection.end)
          let startX, startCenter, endX, endCenter

          // 12 teams
          if (this.numGames == 11) {
            if (start.id === 'game-1') {
              // draw first line from the right side of game 1 to the center of game 5
              startX = start.right + 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 5
              drawLine(endX, startCenter, endX, end.top - 10)

            }

            if (start.id === 'game-2') {
              // draw first line from the left side of game 2 to the center of game 6
              startX = start.left - 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 6
              drawLine(endX, startCenter, endX, end.top - 10)
            }

            if (start.id === 'game-3') {
              // draw first line from the right side of game 3 to the center of game 7
              startX = start.right + 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the bottom of game 7
              drawLine(endX, startCenter, endX, end.bottom + 10)
            }

            if (start.id === 'game-4') {
              // draw first line from the left side of game 4 to the center of game 8
              startX = start.left - 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the bottom of game 8
              drawLine(endX, startCenter, endX, end.bottom + 10)
            }

            if (start.id === 'game-5') {
              // draw first line from the right side of game 5 to the center of game 9
              startX = start.right + 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 9
              drawLine(endX, startCenter, endX, end.top - 10)
            }

            if (start.id === 'game-6') {
              // draw first line from the left side of game 6 to the center of game 10
              startX = start.left - 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 10
              drawLine(endX, startCenter, endX, end.top - 10)
            }

            if (start.id === 'game-7') {
              // draw first line from the right side of game 7 to the center of game 9
              startX = start.right + 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 9
              drawLine(endX, startCenter, endX, end.bottom + 10)
            }

            if (start.id === 'game-8') {
              // draw first line from the left side of game 8 to the center of game 10
              startX = start.left - 10
              startCenter = start.top + start.height / 2
              endX = end.left + end.width / 2
              drawLine(startX, startCenter, endX, startCenter)
              // draw second line down from the end of the first line to the top of game 10
              drawLine(endX, startCenter, endX, end.bottom + 10)
            }
          }

          if (start.id === 'game-9') {
            // draw first line from the right side of game 9 to the center of game 11
            startX = start.right + 10
            startCenter = start.top + start.height / 2
            endX = end.left + end.width / 3
            drawLine(startX, startCenter, endX, startCenter)
            // draw second line down from the end of the first line to the top of game 11
            drawLine(endX, startCenter, endX, end.bottom + 10)
          }

          if (start.id === 'game-10') {
            // draw first line from the left side of game 10 to the center of game 11
            startX = start.left - 10
            startCenter = start.top + start.height / 2
            endX = end.left + end.width / 1.5
            drawLine(startX, startCenter, endX, startCenter)
            // draw second line down from the end of the first line to the top of game 11
            drawLine(endX, startCenter, endX, end.bottom + 10)
          }

          // if (start.left < end.left) {
          //   startX = start.right + 10
          //   endX = end.left - 10
          // } else {
          //   startX = start.left - 10
          //   endX = end.right + 10
          // }

          // startCenter = start.top + start.height / 2
          // endCenter = end.top + end.height / 2
          // const midpoint = (startX + endX) / 2
          // drawLine(startX, startCenter, midpoint, startCenter)
          // drawLine(midpoint, startCenter, midpoint, endCenter)
          // drawLine(midpoint, endCenter, endX, endCenter)

        })

      },
      getMatchup(index) {
        // if the game hasn't been played yet, return TBD
        // if (!this.playoff.games[index]) {
        //   return [
        //     {
        //       name: 'TBD',
        //       seed: 'TBD'
        //     },
        //     {
        //       name: 'TBD',
        //       seed: 'TBD'
        //     }
        //   ]
        // }

        // get the seed numbers for the matchup
        const seed1 = this.playoff.games[index][0]
        const seed2 = this.playoff.games[index][1]
        // get the team names gamesfor the matchup
        const team1 = this.playoff.teams[seed1]
        const team2 = this.playoff.teams[seed2]
        // return the matchup as an array of team objects
        // if null return "TBD"
        return [
          {
            name: team1 || 'TBD',
            seed: seed1 || ''
          },
          {
            name: team2 || 'TBD',
            seed: seed2 || ''
          }
        ]
      },
      getWinner() {
        // get playoff winner
        // get the last element in this.playoff.games
        // get length of this.playoff.games object
        const length = Object.keys(this.playoff.games).length
        const lastGame = this.playoff.games[length]
        // return the winner name and seed of the game
        const winnerSeed = lastGame[2]
        const teamName = this.playoff.teams[winnerSeed]

        return {
          name: teamName || 'TBD',
          seed: winnerSeed
        }
      },
      selectTeam(selectedGame, selectedTeam) {
        this.$emit('select-team', selectedGame, selectedTeam)
      }
      // numGames() {
      //   return Object.values(this.playoff.games).length
      // }
      // initBracket() {
      //   this.playoff = this.playoff
      //   this.drawConnections()
      // },
    },
    computed: {
      numGames() {
        return Object.values(this.playoff.games).length
      },
      // : {
      //   get() {
      //     return this.playoff
      //   },
      //   set(newVal) {
      //     this.playoff = {}
      //   }
      // }
    },
    watch: {
      playoff: {
        handler: function (newVal, oldVal) {
          // this.playoff = newVal
          // initBracket()
          console.log('bracket: watch: playoff')
        }
      },
      // : {
      //   handler: function(newVal, oldVal) {
      //     // this.drawConnections()
      //     console.log('bracket: watch: : numGames', this.numGames)
      //   }
      // }
    },
    updated() {
      // console.log('bracket: updated: this.playoff.games', this.playoff.games)
      // this loads the lines when the data changes
      console.log('bracket: updated: drawConnections')
      this.drawConnections()
    },
    mounted() {
      // console.log('bracket: mounted: this.playoff.games', this.playoff.games)
      // console.log('bracket: mounted: drawConnections')
      // this loads the lines fine on initial load
      this.drawConnections()
      // initBracket()
      // when the window is resized, redraw the connections
      window.addEventListener('resize', this.drawConnections)
    },
    beforeUnmount() {
      // remove the event listener when the component is destroyed
      window.removeEventListener('resize', this.drawConnections)
    }
  }
</script>

<style lang="scss" scoped>
  .bracket-container {
    position: relative;
    height: 100%;

    // background-color: red;
    // padding-left: 220px;
    // flex: 1;
    // position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // position: relative;
    // width: 100%;
    // height: 100%;
    // border: 1px solid red;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      // z-index: 3;
    }
  }

  .bracket {
    display: grid;
    grid-template-columns:
      11.428571428628571% 3.3333333332% 11.428571428628571% 3.3333333332% 11.428571428628571% 3.3333333332% 11.428571428628571% 3.3333333332% 11.428571428628571% 3.3333333332% 11.428571428628571% 3.3333333332% 11.428571428628571%;
    grid-template-rows: 16.666666666666667% 16.666666666666667% 16.666666666666667% 16.666666666666667% 16.666666666666667% 16.666666666666667%;
    height: 100%;
    padding: 0 30px;

    > div {
      // color: red;
      // border: 1px solid red;
      // center the text
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &.four-team {
      .game-1 {
        grid-column: 5 / 6;
        grid-row: 2 / 6;
      }

      .game-2 {
        grid-column: 9 / 10;
        grid-row: 2 / 6;
      }

      .game-3 {
        grid-column: 7 / 8;
        grid-row: 3 / 5;

        .team-badge--winner {
          order: 1;
        }

        .game__score {
          order: 2;
        }

        .team-badge--loser {
          order: 3;
        }
      }
    }

    &.twelve-team {
      .game-1 {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
      }

      .game-2 {
        grid-column: 13 / 14;
        grid-row: 1 / 3;
      }

      .game-3 {
        grid-column: 1 / 2;
        grid-row: 5 / 7;
      }

      .game-4 {
        grid-column: 13 / 14;
        grid-row: 5 / 7;
      }

      .game-5 {
        grid-column: 3 / 4;
        grid-row: 2 / 4;

        #game-5-container {
          flex-direction: column-reverse;
        }
      }

      .game-6 {
        grid-column: 11 / 12;
        grid-row: 2 / 4;

        #game-6-container {
          flex-direction: column-reverse;
        }
      }

      .game-7 {
        grid-column: 3 / 4;
        grid-row: 4 / 6;
      }

      .game-8 {
        grid-column: 11 / 12;
        grid-row: 4 / 6;
      }

      .game-9 {
        grid-column: 5 / 6;
        grid-row: 2 / 6;
      }

      .game-10 {
        grid-column: 9 / 10;
        grid-row: 2 / 6;
      }

      .game-11 {
        grid-column: 7 / 8;
        grid-row: 1 / 4;

        .team-badge--winner {
          order: 1;
        }

        .game__score {
          order: 2;
        }

        .team-badge--loser {
          order: 3;
        }
      }
    }

    .game {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;

      &__trophy {
        width: 40px;
        height: auto;
        align-self: center;
        filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.3));
      }

      .game-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        gap: 0.6rem;
      }

      &__score {
        width: 100%;
        color: var(--text-color);
        font-family: 'factoria-demi';
        font-size: 1.5rem;
      }
    }
  }
</style>
